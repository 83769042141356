import React, { Fragment, useContext, useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { ViewContext, Card, ProductNav, GridTable, Button, Animate, TitleRow, useAPI, Message, CellActions, AuthContext } from 'components/lib';
import colDefs, { dropDown } from './productColDef'
import { DROPDOWN } from 'utils/customColumnTypes';


export function InactiveProducts() {
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const [refreshToken, setRefreshToken] = useState(+new Date());
  const gridRef = useRef();
  const [message, setMessage] = useState('');
  const [gridApi, setGridApi] = useState(null);

  const data = useAPI(`/api/products/inactive?limit=100000`, null, refreshToken);
  const colData = useAPI(`/api/custom-fields/active?fieldsForInactivePage=yes`);
  const [products, setProducts] = useState([]);
  const [customFieldColumns, setCustomFieldColumns] = useState([]);

  useEffect(() => {
    if (!data.loading && data?.data?.length === 0) {
      setMessage('Please load your products to get started')
    }

    if (data?.data) {
      setProducts(data?.data.map((d, index) => ({...d, id: `inactiveProducts-${index + 1}`})));      
    }
  }, [data?.data]);

  useEffect(() => {
    let tmp = [];
    colData?.data?.forEach(cusCol => {
      let defaults = colDefs[cusCol.column_name]
      
      if (cusCol.column_type === DROPDOWN && cusCol.category === 'custom') {
        defaults = {
          ...dropDown, 
          cellEditorParams: {
            ...dropDown.cellEditorParams,
            values: cusCol.column_allowed_options.split(',')
          }
        }
      } else if (cusCol.column_name === 'asin') {
        defaults = {
          ...defaults, 
          cellRendererParams : {...defaults.cellRendererParams, copy }
        }
      }
      
      tmp.push({
        field: cusCol.column_name, 
        headerName: cusCol.column_label, 
        sortable: true, 
        editable: authContext.permission?.editor, 
        minWidth:60,
        ...defaults
      })
    })

    setCustomFieldColumns(tmp)
  }, [colData])

  async function copy(text) {
    try {
      await navigator.clipboard.writeText(text);
      context.notification.show(`copied to clipboard!`, 'success', true);
    } catch (err) {
      console.error('Could not copy text to clipboard:', err);
    }
  }

  function deleteProduct(data, callback) {
    context.modal.show(
      {
        title: 'Delete Product',
        form: {
          id: {
            type: 'hidden',
            value: data.productId,
          },
        },
        buttonText: 'Delete Product',
        text: `Are you sure you want to delete ${data.asin}?`,
        url: '/api/products',
        method: 'DELETE',
        destructive: true,
      },
      () => {
        setRefreshToken(+new Date());
        context.notification.show(data.asin + ' was deleted', 'success', true);
        callback();
      }
    );
  }

  const handleChange = async (row) => {
    const data = { [row.colDef.headerName]: row.newValue, id: row.data.productId, isCustomCol: row.colDef.isCustomCol }
    const index = row.node.rowIndex;

    delete data.productId;

    try {
      const res = await axios({
        url: '/api/products',
        method: 'patch',
        data
      })
  
      const invalidValues = res.data.data.invalidValues;
  
      const invalidKeys = Object.keys(invalidValues);
  
      if(invalidKeys.length > 0){
        const messageStr = `${invalidValues[invalidKeys[0]]} is not a valid value for ${row.colDef.headerName}`;
        throw new Error(messageStr)
      } else {
        const messageStr = `${row.colDef.headerName} updated successfully!`;
        const productWithNewValue = { ...row.data, [row.colDef.field]: row.newValue};
       
        if (productWithNewValue.status !== 'inactive') {
          const rowNode = gridApi.getRowNode(productWithNewValue.id);

          if(rowNode) {
              gridApi.updateRowData({remove: [rowNode.data]});
          }
        } else {
          gridApi.updateRowData({update: [productWithNewValue]});
        }

        context.notification.show(messageStr, 'success', true);        
      }
    } catch (error) {
      const productWithOldValue = { ...row.data, [row.colDef.field]: row.oldValue};
      context.notification.show(error?.response?.data?.message || error.message, 'error', true);
      gridApi.updateRowData({update: [productWithOldValue]});
    }
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <Fragment>
      <ProductNav />
      <Animate>
        {message && <Message text={message} type='info' />}
        <Card>
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <GridTable
            gridRef={gridRef}
            onGridReady={onGridReady}
            gridApi={gridApi}
            width='100%'
            columnDefs={[
              ...customFieldColumns,
              {
                headerName: 'Actions',
                cellRenderer: CellActions,
                cellRendererParams: {
                  delete: deleteProduct, 
                },
                maxWidth: 100,
                filter:false
              }
            ]}
            defaultColDef = {{
             // flex: 1,
              filter: true,
              floatingFilter:true,
              filterParams: {
                excelMode: 'windows',
            },
              resizable: true,
              onCellValueChanged: handleChange
            }}
            rowData={products}
            loading={data.loading}
          />
          </div>
        </Card>
      </Animate>
    </Fragment>
  );
}
