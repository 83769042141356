import { Badge } from 'components/lib';

export const CellTag = ({ value }) => {
    if (!value) { return <div /> };

    const valueArr = value.split(',');

    return valueArr.map(v => <div 
      style={{color: 'black', padding: '1px 6px', backgroundColor: 'lightgrey', float: 'left', borderRadius: '4px', marginLeft: 1}} >
        {v}
    </div>)
}
