/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user editor to only account owners
 *
 **********/

import React, { useContext } from 'react';
import { AuthContext, SubNav } from 'components/lib';

export function AccountUserNav() {
  const authContext = useContext(AuthContext);

  return (
    <SubNav
      items={[
        { link: '/account/users', label: 'Users' },
        (authContext.permission?.master && { link: '/account/other-users', label: 'Other users' })
      ]}
    />
  );
}
