/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Icon, ClassHelper } from 'components/lib';
import Style from './features.tailwind.js';

export function Features(props){

  return(
    <Grid cols={ 3 }>

      <Feature 
        icon='credit-card' 
        title='Monitors Your Entire Catalog'
        desc='Daily checks of your most important product pages.'
      />

      <Feature 
        icon='unlock' 
        title='Verify Buyability'
        desc='Learn about problems before they impact your sales.'
      />

      <Feature 
        icon='droplet' 
        title='Email Alerts'
        desc='Get email updates of critical issues.'
      />

      <Feature 
        icon='twitter' 
        title='Yanked Pages'
        desc='Know when Amazon pulls one of your listings for compliance or other reasons.'
      />

      <Feature 
        icon='settings' 
        title='Missing Buy Box'
        desc='Identify sourcing and buyability issues for all asins.'
      />    

    <Feature 
        icon='settings' 
        title='Alerts on Pricing Issues'
        desc='Identify when Amazon loses the buy box'
      />   

      <Feature 
        icon='database' 
        title='See Amazon Inventory Issues'
        desc='See what skus Amazon is not selling'
      />

    <Feature 
        icon='database' 
        title='Learn When Variations Break'
        desc='Keep your products merchandised correctly'
      />

     <Feature 
        icon='database' 
        title='Title and Bullet Changes'
        desc='Keep unauthorized changes from impacting your sales'
      />



    </Grid>
  );
}

function Feature(props){

  const featureStyle = ClassHelper(Style, {
    
    feature: true, 
    className: props.className  
  
  });

  return (
    <div className={ featureStyle }>

      <Icon image={ props.icon } size={ 16 } className={ Style.icon } />

      <h3 className={ Style.title }>
        { props.title }
      </h3>
      
      <p className={ Style.description }>{ props.desc }</p>

    </div>
  );
}
