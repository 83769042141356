/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Grid, Card, Icon, Link, useAPI, Loader } from 'components/lib';

export function Account(props) {
  const context = useContext(AuthContext);
  const user = useAPI('/api/user');
  const iconSize = 20;

  if (user.loading) return <Loader />;

  return (
    <Animate>
      <Grid cols='4'>
        <Card>
          <Icon image='user' size={iconSize} />
          <h2 className={Style.subtitle}>Profile</h2>
          <div>Update your profile information</div>
          <Link url='/account/profile' className={Style.link}>
            Update
          </Link>
        </Card>

        <Card>
          <Icon image='lock' size={iconSize} />
          <h2 className={Style.subtitle}>Password</h2>
          <div>{user?.data?.['has_password'] ? 'Change your password' : 'Create a password'}</div>
          <Link url='/account/password' className={Style.link}>
            {user?.data?.['has_password'] ? 'Change' : 'Create'}
          </Link>
        </Card>

        {context.permission?.master && (
          <Card>
            <Icon image='credit-card' size={iconSize} />
            <h2 className={Style.subtitle}>Custom Limits</h2>
            <div>Assign custom limits to a user for different priorities</div>
            <Link url='/account/custom-limits' className={Style.link}>
              Manage
            </Link>
          </Card>
        )}
        {context.permission?.owner && (
          <Card>
            <Icon image='credit-card' size={iconSize} />
            <h2 className={Style.subtitle}>Audit Files</h2>
            <Link url='/account/audit' className={Style.link}>
              Manage
            </Link>
          </Card>
        )}        

       {context.permission?.owner && (
          <Card>
            <Icon image='credit-card' size={iconSize} />
            <h2 className={Style.subtitle}>Key Files Page</h2>
            <Link url='/account/key-files' className={Style.link}>
              Manage
            </Link>
          </Card>
        )}        

        {context.permission?.editor && (
          <Card>
            <Icon image="users" size={iconSize} />
            <h2 className={Style.subtitle}>Users</h2>
            <div>Invite users to your account</div>
            <Link url="/account/users" className={Style.link}>
              Add users
            </Link>
          </Card>
        )}

        <Card>
          <Icon image='activity' size={iconSize} />
          <h2 className={Style.subtitle}>Plan Usage</h2>
          <div>Check your plan usage stats</div>
          <Link url='/account/plan-usage' className={Style.link}>
            Show Usage
          </Link>
        </Card>

        {context.permission?.master && (<Card>
          <Icon image='bookmark' size={iconSize} />
          <h2 className={Style.subtitle}>Manage codes</h2>
          <div>Add / Update product issue codes</div>
          <Link url='/account/manage-codes' className={Style.link}>
            Update
          </Link>
        </Card>)}

        {context.permission?.master && (<Card>
          <Icon image='bookmark' size={iconSize} />
          <h2 className={Style.subtitle}>Manage tabs access</h2>
          <div>Allow / restrict users to access different tabs on sidebar</div>
          <Link url='/account/manage-reports' className={Style.link}>
            Update
          </Link>
        </Card>)}        

      </Grid>
    </Animate>
  );
}

const Style = {
  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block',
};
