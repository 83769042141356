export const LOW = 'low';
export const MEDIUM = 'medium';
export const HIGH = 'high';
export const UNSCANNED = 'unscanned';

export const PRIORITIES = [
  { value: LOW, label: 'Low' },
  { value: MEDIUM, label: 'Medium' },
  { value: HIGH, label: 'High' }, 
  { value: UNSCANNED, label: 'Not scanned' },
];
  