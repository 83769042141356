import React, { Fragment, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { ViewContext, Card, AccountNav, GridTable, Button, Animate, TitleRow, useAPI, Message, CellActions } from 'components/lib';
import productColDef from '../products/productColDef';
import { SEVERITY_OPTIONS } from 'utils/productIssueSeverity';

export function ProductIssueCode (props) {
    const context = useContext(ViewContext);
    const [refreshToken, setRefreshToken] = useState(+new Date());
    const gridRef = useRef();
  
    const data = useAPI(`/api/product-issue-code`, null, refreshToken);
    const [codes, setCodes] = useState([]);
  
    useEffect(() => {
      if (data?.data) {
        setCodes(data?.data);      
      }
    }, [data?.data]);

    function editCode(data, callback) {
        context.modal.show(
          {
            title: 'Update Code',
            form: {
              code: {
                label: 'Code',
                type: 'text',
                value: data.code,
                readonly: 'readonly',
                required: true
              },
              severity: {
                label: 'Severity',
                type: 'select',
                default: data.severity,
                options: SEVERITY_OPTIONS,
                required: true
              },
            },
            buttonText: 'Save',
            url: '/api/product-issue-code',
            method: 'PATCH',
          },
          (form, res) => {
            context.notification.show(data.code + ' was updated', 'success', true);
            setRefreshToken(+new Date());
            callback(res);
          }
        );
      }

      function addCode() {
        context.modal.show(
          {
            title: 'Update Code',
            form: {
              code: {
                label: 'Code',
                type: 'text',
                required: true
              },
              severity: {
                label: 'Severity',
                type: 'select',
                options: SEVERITY_OPTIONS,
                required: true
              },
            },
            buttonText: 'CREATE',
            url: '/api/product-issue-code',
            method: 'POST',
          },
          (form, res) => {
            context.notification.show(res.code + ' was created', 'success', true);
            setRefreshToken(+new Date());
          }
        );
      }      

    return (
        <Fragment>
          {!props.hideNav && <AccountNav />}
          <Animate>    
          <TitleRow title='Unresolved Issues'>          
            <Button small text='Add code' action={addCode} />
          </TitleRow>

            <Card>
              <div style={{display: 'flex', justifyContent: 'center'}}>
              <GridTable
                gridRef={gridRef}
                width='400px'
                columnDefs={[
                    {
                        ...productColDef.code,
                        minWidth: 300,
                        maxWidth: 300
                    },
                    {
                        headerName: 'Actions',
                        cellRenderer: CellActions,
                        sortable:false,
                        filter:false,
                        maxWidth:100,
                        cellRendererParams: {
                          edit: editCode
                        }
                      }                ]}
                defaultColDef = {{
                  flex: 1,
                  filter: true,
                  filterParams: {
                    excelMode: 'windows',
                },
                  floatingFilter:true,
                  resizable: true
                }}
                rowData={codes}
                loading={data.loading}
              />
              </div>
            </Card>
          </Animate>
        </Fragment>
      );
}