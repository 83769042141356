/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user user to only account owners
 *
 **********/

import React from 'react';
import { SubNav } from 'components/lib';

export function ProductNav(props) {
  return (
    <SubNav
      items={[
        { link: '/products', label: 'My Products', permission: 'user' },
        { link: '/products/inactive', label: 'Inactive Products', permission: 'user' },
        {
          link: '/products/actuals',
          label: 'Amazon Live Data',
          permission: 'user',
        }, 
        {
          link: '/products/diff',
          label: 'Differences',
          permission: 'user',
        }, 
        {
          link: '/products/history',
          label: 'Product History',
          permission: 'user',
        },
        {
          link: '/products/custom-fields',
          label: 'Custom fields settings',
          permission: 'owner',
        }

      ]}
    />
  );
}
