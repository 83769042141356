import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Card, ProductIssueNav, GridTable, Animate, useAPI, ViewContext, RichTextEditor } from 'components/lib';
import colDefs from '../products/productColDef'

export function History(props) {

  const data = useAPI(`/api/product-issue/history`, null);
  const context = useContext(ViewContext);
  const [productIssues, setProductIssues] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setProductIssues(data.data)
    }
  }, [data?.data]);

  async function copy(text) {
    try {
      await navigator.clipboard.writeText(text);
      context.notification.show(`copied to clipboard!`, 'success', true);
    } catch (err) {
      console.error('Could not copy text to clipboard:', err);
    }
  }

  return (
    <Fragment>
      {!props.hideNav && <ProductIssueNav />}
      <Animate>

        <Card name='unresolvedIssues' title='Historical Issues'>
          <GridTable
             width='95%'
             columnDefs={[
              {
                ...colDefs.asin,
                cellRendererParams : {...colDefs.asin.cellRendererParams, copy }
              }, 
              colDefs.market, 
              {
                ...colDefs.title_master,
                cellRenderer: (params) => <RichTextEditor disabled={true} value={params.value} />,
                editable: false
              }, 
              {...colDefs.brand, editable: false}, 
              colDefs.sku, 
              colDefs.code, 
              colDefs.first_scan, 
              colDefs.last_scan, 
              colDefs.comment, 
              {...colDefs.priority, editable: false}
             ]}
 
             rowData={productIssues}
             loading={data.loading}
          />
        </Card>
      </Animate>
    </Fragment>
  );
}
