import { CellLink, CellStar, CellBadge, CellTag, DatepickerEditor, LargeTextAreaEditor } from 'components/lib';
import {DROPDOWN, TEXT, DATE, NUMBER} from 'utils/customColumnTypes';
import { ACTIVE, INACTIVE } from 'utils/status';
import { MARKETS } from '../../utils/constants';
import { relativeTime } from '../../utils/moment';
import { HIGH, LOW, MEDIUM, UNSCANNED } from 'utils/productPriority';
import moment from 'moment';

let formattedMarkets = {};
MARKETS.forEach(market => {
  formattedMarkets = {
    ...formattedMarkets,
    [market.value]: market.amazonUrl
  }
})

class TypeCellRenderer {
  init(params) {

    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `${params.value}`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
};

const isCellEditable = (params) => params.data.canEdit;

export default {
  productId: {field: 'productId', hide: true, editable: false},
  asin: {
    field: 'asin',
    headerName: 'ASIN',
    sortable: true, 
    minWidth: 140,
    width: 150,
    editable: false,
    cellRenderer: CellLink,
    cellRendererParams: {
      linkMap: formattedMarkets,
      copyToClipboard: true
    }
  },
  market: {field: 'market', headerName: 'Market', sortable: true, maxWidth: 100, width: 80, editable: false, minWidth:60},
  title_master: {
    field: 'title_master',
    headerName: 'Title',
    sortable: true,
    // flex:3,
    minWidth:120,
    // textArea: true, textAreaTemplate: '<textarea className="ag-custom-textarea"></textarea>'
  },
  brand:  {field: 'brand_master', headerName: 'Brand', sortable: true,minWidth:80, maxWidth:140}, 
  last_scan: {
    field: 'last_scan', 
    sortable: true, 
    headerName: 'Last scan', 
    valueFormatter: p => p.value ? relativeTime(p.value) : '',
    maxWidth: 180,
    editable: false
  },
  first_scan: {
    field: 'first_scan', 
    sortable: true, 
    headerName: 'First scan', 
    valueFormatter: p => p.value ? relativeTime(p.value) : '',
    maxWidth: 180
  },
  paused_until: {
    field: 'paused_until', 
    sortable: true, 
    headerName: 'Paused until', 
    valueFormatter: p => p.value ? moment(p.value).format('YYYY-MM-DD') : '',
    maxWidth: 180,
    cellEditorFramework: DatepickerEditor,
  },
  issue_paused_until: {
    field: 'issue_paused_until', 
    sortable: true, 
    headerName: 'Issue paused until', 
    valueFormatter: p => p.value ? moment(p.value).format('YYYY-MM-DD') : '',
    maxWidth: 180,
    cellEditorFramework: DatepickerEditor
  },      
  first_scan: {
    field: 'first_scan', 
    sortable: true, 
    headerName: 'First seen', 
    valueFormatter: p => p.value ? relativeTime(p.value) : '',
    maxWidth: 100,
    width:100,
    editable: false
  },
  last_scan: {
    field: 'last_scan', 
    sortable: true, 
    headerName: 'Last seen', 
    valueFormatter: p => p.value ? relativeTime(p.value) : '',
    maxWidth: 100,
    width:95,
    editable: false
  },
  created_at: {
    field: 'created_at', 
    sortable: true, 
    headerName: 'Created at', 
    valueFormatter: p => moment(p.value).format('lll'),
    maxWidth: 180,
    width:180
  },
  priority: {
    field: 'priority',
    headerName:'Priority',
    maxWidth:140,
    width:100,
    minWidth:60,
    cellRenderer: CellStar,
    cellRendererParams: {
      mapping: {
        high: 5,
        medium: 3,
        low: 1,
        unscanned: 0,
    },
  },
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [HIGH, MEDIUM, LOW, UNSCANNED]
  },    
    sortable: true
  }, 

  sku: {field: 'sku', sortable: true, headerName: 'SKU', maxWidth: 150, width:80, minWidth:40},
  is_resolved_by_user: {
    field: 'is_resolved_by_user', 
    headerName:'Resolved',
    valueFormatter: p => p.value === 'no' ? 'No' : 'Yes',
    hide: true 
  },
  comment: {field: 'comment', sortable: true, width: 130},
  column_name: {field: 'column_name', headerName: 'Column Name', width: 130, sortable: true},
  old_value: {field: 'old_value', headerName: 'Old value', sortable: true, flex:1},
  new_value: {field: 'new_value', headerName: 'New value', sortable: true, flex:1},
  created_by: {field: 'created_by', headerName: 'Created by', sortable: true},
  master_account_id: {field: 'master_account_id', headerName: 'Master Account ID', sortable: true},
  scan_count: {field: 'scan_count', sortable: true, headerName: 'Scan count', maxWidth: 100, editable: false},
  status: {
    field: 'status',
    maxWidth:140,
    width:100,
    minWidth:60,
    cellRenderer: CellBadge,
    cellRendererParams: {
      badgeFor: 'status',
      mapping: {
        active: 'green',
        inactive: 'red',
        paused: 'blue',
      }
    }, 
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [ACTIVE, INACTIVE]
  },    
    sortable: true
  },
  issue_status: {
    field: 'issue_status',
    maxWidth:140,
    width:100,
    minWidth:60,
    cellRenderer: CellBadge,
    cellRendererParams: {
      badgeFor: 'status',
      mapping: {
        active: 'green',
        inactive: 'red',
        paused: 'blue',
      }
    }, 
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [ACTIVE, INACTIVE]
  },    
    sortable: true
  }, 
  code: {
    field: 'code',
    // headerName:'Issue',
    maxWidth:180,
    cellRenderer: CellBadge,
    cellRendererParams: {
      mapping: {
        error: 'red',
        warning: 'blue',
      }
    },
    sortable: true
  },

  // Actual fields starting below
  title_actual:{field: 'title_actual', headerName: 'Title', sortable: true, maxWidth: 280, width:140, minWidth:60},
  brand_actual:{field: 'brand_actual', headerName: 'Brand', sortable: true, maxWidth: 80, minWidth:60},
  variation_parent_group_actual:{field: 'variation_parent_group_actual', headerName: 'variation parent Group', sortable: true, minWidth:60},
  bullet_1_actual:{field: 'bullet_1_actual', headerName: 'Bullet 1', sortable: true, minWidth:60},
  bullet_2_actual:{field: 'bullet_2_actual', headerName: 'Bullet 2', sortable: true, minWidth:60},
  bullet_3_actual:{field: 'bullet_3_actual', headerName: 'Bullet 3', sortable: true, minWidth:60},
  bullet_4_actual:{field: 'bullet_4_actual', headerName: 'Bullet 4', sortable: true, minWidth:60},
  bullet_5_actual:{field: 'bullet_5_actual', headerName: 'Bullet 5', sortable: true, minWidth:60},
  description_actual:{field: 'description_actual', headerName: 'Description', sortable: true, minWidth:60},
  list_price_actual:{field: 'list_price_actual', headerName: 'List price', sortable: true, minWidth:60},
  style_actual:{field: 'style_actual', headerName: 'Style', sortable: true, minWidth:60},
  size_actual:{field: 'size_actual', headerName: 'Size', sortable: true, minWidth:60},
  color_actual:{field: 'color_actual', headerName: 'Color', sortable: true, minWidth:60},
  part_number_actual:{field: 'part_number_actual', headerName: 'Part number', sortable: true, minWidth:60},
  model_number_actual:{field: 'model_number_actual', headerName: 'Model number', sortable: true, minWidth:60},
  number_of_items_actual:{field: 'number_of_items_actual', headerName: 'Number of items', sortable: true, minWidth:60},
  browse_node_actual:{field: 'browse_node_actual', headerName: 'Browse node', sortable: true, minWidth:60},
  category_actual:{field: 'category_actual', headerName: 'Category', sortable: true, minWidth:60},
  has_aplus_actual:{field: 'has_aplus_actual', headerName: 'A+', sortable: true, minWidth:40, width:80, maxWidth:120},
  number_of_bullet_points_actual:{field: 'number_of_bullet_points_actual', headerName: 'Number of bullet points', sortable: true, minWidth:60},
  number_of_images_actual:{field: 'number_of_images_actual', headerName: 'Number of images', sortable: true, minWidth:60},
  main_image_size_actual:{field: 'main_image_size_actual', headerName: 'Main image size', sortable: true, minWidth:60},
  number_of_videos_actual:{field: 'number_of_videos_actual', headerName: 'Number of videos', sortable: true, minWidth:60},
  review_ratings_actual:{field: 'review_ratings_actual', headerName: 'Review ratings', sortable: true, minWidth:60},
  review_average_rating_actual:{field: 'review_average_rating_actual', headerName: 'Review average rating', sortable: true, minWidth:60},
  retail_price_actual:{field: 'retail_price_actual', headerName: 'Retail price', sortable: true, minWidth:60},
  amazon_offer_has_buybox_actual:{field: 'amazon_offer_has_buybox_actual', headerName: 'Amazon offer has buybox', sortable: true, minWidth:60},
  buybox_fufillment_type_actual:{field: 'buybox_fufillment_type_actual', headerName: 'Buybox fufillment type', sortable: true, minWidth:60},
  best_seller_rank_actual:{field: 'best_seller_rank_actual', headerName: 'Best seller rank', sortable: true, minWidth:60},
  best_seller_rank_category_actual:{field: 'best_seller_rank_category_actual', headerName: 'Best seller rank category', sortable: true, minWidth:60},
  amazon_choice_actual:{field: 'amazon_choice_actual', headerName: 'Amazon choice', sortable: true, minWidth:60},       
    // Actual fields ended here
  
  column_label: {field: 'column_label', headerName: 'Label', sortable: true, minWidth:200, editable: (p) => isCellEditable(p)},
  column_type: {
    field: 'column_type', 
    headerName: 'Type', 
    sortable: true, 
    minWidth: 200,
    editable: (p) => isCellEditable(p),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    cellRenderer: TypeCellRenderer,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [DROPDOWN, TEXT, DATE, NUMBER]
  }},
  column_allowed_options: {
    field: 'column_allowed_options',
    headerName: 'Allowed options', 
    sortable: true, 
    minWidth:200, 
    editable: (p) => isCellEditable(p),
    cellRenderer: CellTag,
  },
  position_in_product_table: {
    field: 'position_in_product_table', 
    headerName: 'Position', 
    sortable: true, 
    minWidth:200, 
    editable: true,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    cellRenderer: TypeCellRenderer,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [1, 2, 3]
    }    
  },
  status_in_product_table: {
    field: 'status_in_product_table',
    headerName:'Status',
    maxWidth:140,
    width:100,
    minWidth:60,
    editable: true,
    cellRenderer: CellBadge,
    cellRendererParams: {
      badgeFor: 'status',
      mapping: {
        active: 'green',
        inactive: 'red',
        paused: 'blue',
      }
    },
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [ACTIVE, INACTIVE]
  },    
    sortable: true
  },
  position_in_issue_table: {
    field: 'position_in_issue_table', 
    headerName: 'Position', 
    sortable: true, 
    minWidth:200, 
    editable: true,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    cellRenderer: TypeCellRenderer,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [1, 2, 3]
    }    
  },
  status_in_issue_table: {
    field: 'status_in_issue_table',
    headerName:'Status',
    maxWidth:140,
    width:100,
    minWidth:60,
    editable: true,
    cellRenderer: CellBadge,
    cellRendererParams: {
      badgeFor: 'status',
      mapping: {
        active: 'green',
        inactive: 'red',
        paused: 'blue',
      }
    },
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    keyCreator: (params) => {
      return params.value;
    },
    cellEditorParams: {
      cellRenderer: TypeCellRenderer,
      values: [ACTIVE, INACTIVE]
  },    
    sortable: true
  },  
  field_name: {field: 'field_name', headerName:'Field',  sortable: true, editable: false, minWidth:60, width:100},
  master_value: {field: 'master_value',headerName:'Expected',  sortable: true, editable: true, minWidth:60, width: 300},
  actual_value: {field: 'actual_value', headerName:'Found', sortable: true, editable: false, minWidth:60, width:300},
}

export const dropDown = {
  sortable: true, 
  minWidth:200, 
  editable: true,
  cellEditor: 'agRichSelectCellEditor',
  cellEditorPopup: true,
  cellRenderer: TypeCellRenderer,
  cellEditorParams: {
    cellRenderer: TypeCellRenderer,
    values: []
  }
}