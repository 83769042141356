/***
 *
 *   ProductIssue NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user user to only account owners
 *
 **********/

 import React from 'react';
 import { SubNav } from 'components/lib';
 
 export function ProductIssueNav(props) {
   return (
     <SubNav
       items={[
        {
          link: '/issues',
          label: 'Open Issues',
          permission: 'user',
        },
        {
          link: '/issues/paused',
          label: 'Paused Issues',
          permission: 'user',
        },
       {
           link: '/issues/resolved',
           label: 'Resolved Issues',
           permission: 'user',
         },
         {
           link: '/issues/history',
           label: 'Issues History',
           permission: 'user',
         },
         {
          link: '/issues/custom-fields',
          label: 'Custom fields settings',
          permission: 'owner',
        }         
       ]}
     />
   );
 }
 