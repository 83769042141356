/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user editor to only account owners
 *
 **********/

import React from 'react';
import { SubNav } from 'components/lib';

export function AccountNav(props) {
  return (
    <SubNav
      items={[
        { link: '/account/profile', label: 'Profile' },
        { link: '/account/password', label: 'Password' },
        // { link: '/account/billing', label: 'Billing', permission: 'owner' },
        { link: '/account/custom-limits', label: 'Custom Limits', permission: 'master' },
        { link: '/account/audit', label: 'Audit Files', permission: 'owner' },
        { link: '/account/key-files', label: 'Key Files Page', permission: 'owner' },
        { link: '/account/users', label: 'Users', permission: 'owner' },
        { link: '/account/manage-codes', label: 'Manage issue codes', permission: 'master' },      
        { link: '/account/manage-reports', label: 'Manage tabs access', permission: 'master' },      
        { link: '/account/plan-usage/', label: 'Plan Usage', permission: 'user' },
      ]}
    />
  );
}
