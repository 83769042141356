import { ModalWithoutContext } from "components/lib";
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";

function LargeTextAreaEditor(props, ref) {
  const [value, setValue] = useState(props.value);
  const textAreaRef = useRef(null);

  useEffect(() => {
    textAreaRef.current.focus();
    textAreaRef.current.select();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return value;
    }
  }));

  function handleChange(event) {
    setValue(event.target.value);
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      // Enter key pressed without shift key
      props.stopEditing();
    } else if (event.keyCode === 27) {
      // Escape key pressed
      props.stopEditing(true);
    }
  }

  return (
    <ModalWithoutContext>
      <textarea
        ref={textAreaRef}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={{ height: "500px", width: "100%" }}
      />
    </ModalWithoutContext>
  );
}

export default React.forwardRef(LargeTextAreaEditor);
