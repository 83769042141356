/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, {useState, useEffect} from 'react';
import { Grid, Animate, Stat, useAPI, Message,BlankSlateMessage, Alert } from 'components/lib';
import { ProductsFindings } from '../productIssues/productsFindings';
import axios from 'axios';

export function Dashboard(props) {
  const getAlerts = useAPI('/api/account/alerts')
  const [refershToken, setRefreshToken] = useState(null)
  const [message, setMessage] = useState('')
  const [alerts, setAlerts] = useState([]);

  const stats = useAPI('/api/account/stats', null, refershToken)

  useEffect(() => {
    if (getAlerts.data) {
      setAlerts([...getAlerts.data])
    }
  }, [getAlerts])

  useEffect(() => {
    if (!stats?.loading && stats.data) {
      const { numberOfOpenIssues, numberOfProductsInCatalog, numberOfTotalScans} = stats.data;
      if (numberOfProductsInCatalog === 0 && numberOfOpenIssues === 0) {
        setMessage('Click on products and load your products')
      } else if (numberOfOpenIssues === 0 && numberOfTotalScans === 0) {
        setMessage('Please wait for asinrx to scan your products and post your results')
      } else if (numberOfProductsInCatalog > 0 && numberOfTotalScans > 0 && numberOfOpenIssues === 0) {
        setMessage('Congradulations on having no issues')
      }
    }
  }, [stats.data])
  
  const markAsRead = async (id) => {
    try {
      const res = await axios({
        url: '/api/account/mark-alert-as-read',
        method: 'patch',
        data: {alertId: id}
      })
      
      if (res.data.data === 1) {
      setAlerts(alerts.filter(a => a.id !== id))      
      }

    } catch (error) {
      console.log({ error });
    }
  }
  return (
    <Animate type='pop'>
      {message && <Message
        text={message}
        type='info'
      />}
      <Grid cols='4'>
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.numberOfOpenIssues }
          label='Number of open issues'
          icon='users'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.numberOfProductsInCatalog }
          label='Number of total products in catalog'
          icon='check'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.lastScannedDate }
          label='Last scan'
          icon='refresh-cw'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.numberOfTotalScans }
          label='Number of total scans'
          icon='calendar'
        />
      </Grid>
      <Grid>
        {alerts && alerts.map(alert => <Alert text={alert.body} id={alert.id} onClick={markAsRead} />)}
      </Grid>
      <Grid>
        <ProductsFindings search={false} updateStats = {() => setRefreshToken( +new Date() )} hideNav hideUploadBtn />
      </Grid>
    </Animate>
  );
}
