import React from 'react';
import { Loader } from 'components/lib'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './gridTable.css'
export const GridTable = ({
    columnDefs = [],
    rowData = [],
    width = 1000,
    defaultColDef = {},
    loading = false,
    gridRef = null,
    onGridReady = () => {},
    gridApi = null,
    excelStyles = []
}) => {
    return loading ? 
    <div>
        <Loader />
    </div>
    : (
        <div className="ag-theme-balham" style={{height: 1000, width}}>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                excelStyles={excelStyles}
                columnDefs={columnDefs}
                animateRows={true} 
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                gridApi={gridApi}
                getRowId = {(params) => {
                    return params.data.id
                }}
                getRowNodeId = {(params) => {
                    return params.data.id
                }}
            />
        </div>
    )
}