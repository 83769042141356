import Parser from 'html-react-parser';

const Alert = ({
  text = '',
  type = 'error',
  id = '',
  onClick = () => {}
}) => {
  return (
    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <span class="block sm:inline">{Parser(text)}</span>
      <span class="absolute top-0 bottom-0 right-0 px-4 py-2">
        <button class="fill-current text-red-500 hover:bg-red-700 hover:text-white bg-red-200 border-red-900 py-1 px-2 rounded-full" onClick={() => onClick(id)}>
          Mark as read
        </button>            
      </span>
    </div>
  )
};

export { Alert }