import React, { Fragment, useContext, useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { ViewContext, Card, ProductNav, GridTable, Button, Animate, TitleRow, useAPI, Message, CellActions, AuthContext, RichTextEditor, ModalWithoutContext } from 'components/lib';
import { MARKETS } from '../../utils/constants';
import colDefs, { dropDown } from './productColDef'
import { PRIORITIES } from 'utils/productPriority';
import { DROPDOWN, TEXT } from 'utils/customColumnTypes';
import { GeneralUpload } from './generalUpload';
import moment from 'moment';
import Style from 'components/form/form.tailwind';

const RICH_COLS = ['description_master', 'notes_master', 'title_master', 'bullet_1_master', 'bullet_2_master', 'bullet_3_master', 'bullet_4_master', 'bullet_5_master'];
const IMMUTABLE_COLS = ['market', 'first_scan', 'last_scan'];

export function Products(props) {
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const [refreshToken, setRefreshToken] = useState(+new Date());
  const gridRef = useRef();
  const [message, setMessage] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [colId, setColId] = useState(null);
  const [oldValueOfSelectedCell, setOldValueOfSelectedCell] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedModalValue, setSelectedModalValue] = useState('');
  const data = useAPI(`/api/products?limit=100000`, null, refreshToken);
  const colData = useAPI(`/api/custom-fields/active`);
  const [products, setProducts] = useState([]);
  const [customFieldColumns, setCustomFieldColumns] = useState([]);
  const [generalUploadModal, setGeneralUploadModal] = useState(false);
  const [excelStyles, setExcelStyles] = useState([]);

  useEffect(() => {
    if (!data.loading && data?.data?.length === 0) {
      setMessage('Please load your products to get started')
    }

    if (data?.data) {
      setProducts(data?.data.map((d, index) => ({...d, id: `products-${index + 1}`})));      
    }
  }, [data?.data]);

  useEffect(() => {
    let tmp = [];

    colData?.data?.forEach(cusCol => {
      let cellClass = {};
      let defaults = colDefs[cusCol.column_name] || {}

      if (cusCol.column_type === DROPDOWN && cusCol.category === 'custom') {
        defaults = {
          ...dropDown, 
          cellEditorParams: {
            ...dropDown.cellEditorParams,
            values: cusCol.column_allowed_options.split(',')
          }
        }
      } else if (RICH_COLS.includes(cusCol.column_name)) {
        defaults = {
          cellRenderer: (params) => <RichTextEditor disabled={true} value={params.value} />,
          editable: false
        }
      } else if (cusCol.column_name === 'asin') {
        defaults = {
          ...defaults, 
          cellRendererParams : {...defaults.cellRendererParams, copy }
        }
      }
      
      if (cusCol.column_type === TEXT) {
        cellClass = {cellClass: 'stringType'}

      setExcelStyles([...excelStyles, {
        id: 'stringType',
        dataType: 'String',
    }])
      }


      tmp.push({
        field: cusCol.column_name, 
        headerName: cusCol.column_label, 
        sortable: true, 
        editable: IMMUTABLE_COLS.includes(cusCol.column_name) ? false : authContext.permission?.editor, 
        minWidth:60,
        ...defaults,
        ...cellClass,
      })
    })

    setCustomFieldColumns(tmp)
  }, [colData, gridApi])

  async function copy(text) {
    try {
      await navigator.clipboard.writeText(text);
      context.notification.show(`copied to clipboard!`, 'success', true);
    } catch (err) {
      console.error('Could not copy text to clipboard:', err);
    }
  }

  function addProduct() {
    context.modal.show(
      {
        title: 'Add Product',
        form: {
          asin: {
            label: 'ASIN',
            type: 'text',
            required: true,
          },
          sku: {
            label: 'SKU',
            type: 'text',
          },
          priority: {
            label: 'Priority',
            type: 'select',
            default: 'low',
            options: PRIORITIES,
          },
          market: {
            label: 'Market',
            type: 'select',
            default: 'US',
            options: MARKETS,
          },
        },
        buttonText: 'Add',
        url: '/api/products',
        method: 'POST',
      },
      (form, res) => {
        const failedProduct = Object.values(res.remainingProducts).find(a => a.length > 0);
        const invalidProduct = res.invalidProducts ? Object.values(res.invalidProducts) : [];
        if (failedProduct) {
          context.notification.show(`Failed to add ${failedProduct[0].asin}`, 'error', true);
        } else if (invalidProduct.length > 0) {
          context.notification.show(`Failed to add ${invalidProduct[0].asin}`, 'error', true);
        } else {
          setRefreshToken(+new Date() + Math.random());
          context.notification.show(`Product ${failedProduct[0].asin} added successfully!`, 'success', true);
        }
      }
    );
  }

  function deleteProduct(data, callback) {
    context.modal.show(
      {
        title: 'Delete Product',
        form: {
          id: {
            type: 'hidden',
            value: data.productId,
          },
        },
        buttonText: 'Delete Product',
        text: `Are you sure you want to delete ${data.asin}?`,
        url: '/api/products',
        method: 'DELETE',
        destructive: true,
      },
      () => {
        setRefreshToken(+new Date());
        context.notification.show(data.asin + ' was deleted', 'success', true);
        callback();
      }
    );
  }

  function addMultiAsinsViaInput() {
    context.modal.show(
      {
        title: 'Add Product',
        startTextList: ['Here you can add multiple products by placing one ASIN in one line in the text area', 'select the market for all products', 'click "Add" button'],
        form: {
          asins: {
            label: 'ASIN',
            type: 'textarea',
            required: true,
          },
          market: {
            label: 'Market',
            type: 'select',
            default: 'US',
            options: MARKETS,
          },
        },
        buttonText: 'Add',
        url: '/api/products/manual-input',
        method: 'POST',
      },
      (form, res) => {
        let data = '';

        const remainingProductsArr = [].concat.apply([], Object.values(res.remainingProducts));
        const remainingProductsStr = remainingProductsArr.map((a) => a.asin).join('\n');
        data = data + remainingProductsStr;

        const existingProductsArr = res.existingProducts ? [].concat.apply([], Object.values(res.existingProducts)) : [];

        if (existingProductsArr.length > 0) {
          const existingProductsStr = existingProductsArr.join('\n');
          const sectionB = ['\n' + '\n' + 'Existing Products'] + '\n' + existingProductsStr;
          data = data + sectionB;
        }

        const invalidProductsArr = res.invalidProducts ? [].concat.apply([], Object.values(res.invalidProducts)) : [];

        if (invalidProductsArr.length > 0) {
          const invalidProductsStr = invalidProductsArr.map((a) => a.asin).join('\n');
          const sectionC = ['\n' + '\n' + 'Invalid Products'] + '\n' + invalidProductsStr;
          data = data + sectionC;
        }

        refreshAndDownload('text/plain', data, 'ASINRX-left out products.txt');
      }
    );
  }

  function refreshAndDownload(type, data, filename) {
    setRefreshToken(+new Date());

    if (data.length > 0) {
      context.notification.show(`Please see the downloaded ${filename} for product not uploaded.`, 'error', true);

      const element = document.createElement('a');
      element.id = +new Date();
      const file = new Blob([data], {
        type,
        endings: 'native',
      });

      element.href = URL.createObjectURL(file);
      element.download = filename;
      document.body.appendChild(element);
      element.click();
      element.outerHTML = '';
    }
  }

  const downloadCSV = useCallback(() => {
    if (products.length > 0) {
      gridRef.current.api.exportDataAsExcel({
        fileName: 'ProductMaster',
        sheetName: 'ProductMaster',
      });
    } else {
      context.notification.show('No products added yet!', 'error', true);
    }

  }, [products, customFieldColumns]);

  const handleChange = async (row) => {
    const data = { [row.colDef.headerName]: row.newValue, id: row.data.productId, isCustomCol: row.colDef.isCustomCol }

    delete data.productId;

    try {
      const res = await axios({
        url: '/api/products',
        method: 'patch',
        data
      })
  
      const invalidValues = res.data.data.invalidValues;
  
      const invalidKeys = Object.keys(invalidValues);
  
      if(invalidKeys.length > 0){
        const messageStr = `${invalidValues[invalidKeys[0]]} is not a valid value for ${row.colDef.headerName}`;
        throw new Error(messageStr)
      } else {
        const messageStr = `${row.colDef.headerName} updated successfully!`;
        let productWithNewValue = { ...row.data, [row.colDef.field]: row.newValue};
        
        if (productWithNewValue.status === 'inactive') {
          const rowNode = gridApi.getRowNode(productWithNewValue.id);

          if(rowNode) {
              gridApi.updateRowData({remove: [rowNode.data]});
          }
        } else {
          if (row.colDef.field === 'paused_until') {
            if (moment(row.newValue) > moment()) {
              productWithNewValue = { ...row.data, status: 'paused'};
            } else {
              productWithNewValue = { ...row.data, paused_until: '', status: 'active'};
            }
          }
  
          gridApi.updateRowData({update: [productWithNewValue]});
        }
        
        
        context.notification.show(messageStr, 'success', true);    
      }
    } catch (error) {
      const productWithOldValue = { ...row.data, [row.colDef.field]: row.oldValue};
      context.notification.show(error?.response?.data?.message || error.message, 'error', true);
      gridApi.updateRowData({update: [productWithOldValue]});
    }
  }

  function toggleUploadProcess() {
    if (generalUploadModal) {
      setRefreshToken(+new Date());
    }
    setGeneralUploadModal(!generalUploadModal)
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleCellClicked= (params) => {
    if (RICH_COLS.includes(params.colDef.field)) {
      setRowData(params.data);
      setColId({headerName: params.colDef.headerName, field: params.colDef.field});
      setSelectedModalValue(params.value)
      setOldValueOfSelectedCell(params.value)
      setShowModal(true);
    }
  };

  const handleSave = () => {
    const updatedRowData = { ...rowData, [colId.field]: selectedModalValue };
    gridApi.updateRowData({update: [updatedRowData]});
    
    if (oldValueOfSelectedCell !== selectedModalValue) {
      handleChange({
        data: updatedRowData,
        colDef: { headerName: colId.headerName },
        newValue: selectedModalValue,
      }); 
    }

    handleCloseModal();
  };

  const handleCloseModal = e => {
    setRowData(null);
    setColId(null);
    setOldValueOfSelectedCell(null);
    setShowModal(false);
  }

  return (
    <Fragment>
      {!props.hideNav && <ProductNav />}
      {generalUploadModal && <GeneralUpload handleCloseModal={toggleUploadProcess}/>}
      <Animate>
        {message && <Message text={message} type='info' />}
          <TitleRow title='My Products'>
            <div style={{ display: 'flex' }}>
            { !props.hideUploadBtns && authContext.permission?.editor && ( <Button small style={{ marginRight: '10px' }} color='blue' text='Add Multiple ASINs' action={addMultiAsinsViaInput} /> )} 
            { !props.hideUploadBtns && authContext.permission?.editor && ( <Button small text='Add a Single ASIN' color='blue' action={addProduct} style={{ marginRight: '10px' }} /> )}
            { !props.hideUploadBtns && authContext.permission?.editor && ( <Button small text='Upload' color='blue' action={toggleUploadProcess} style={{ marginRight: '10px' }} /> )}
            <Button small color='green' text='Download' action={downloadCSV} />
            </div>
          </TitleRow>

        <Card>
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <GridTable
            gridRef={gridRef}
            onGridReady={onGridReady}
            width='100%'
            gridApi={gridApi}
            excelStyles={excelStyles}
            columnDefs={[
              ...customFieldColumns,
              {
                headerName: 'Actions',
                cellRenderer: CellActions,
                cellRendererParams: {
                  delete: deleteProduct, 
                },
                maxWidth: 100,
                filter:false
              }
            ]}
            defaultColDef = {{
              filter: true,
              floatingFilter:true,
              filterParams: {
                excelMode: 'windows',
            },
              resizable: true,
              onCellValueChanged: handleChange,
              onCellDoubleClicked: handleCellClicked
            }}
            rowData={products}
            loading={data.loading}
          />
          </div>
        </Card>
      </Animate>
      {showModal && <ModalWithoutContext onClose={handleCloseModal} >
        <Card style={{marginBottom: '1.5rem'}}>
          <RichTextEditor disabled={!showModal} value={selectedModalValue} onChange={text => setSelectedModalValue(text)}/>
          
          <div>
            <Button color='green' text='Update' action={ handleSave } className={ Style.button } />
            <Button color='red' text='Cancel' action={ handleCloseModal } className={ Style.button } />
          </div>
        </Card>
          
        </ModalWithoutContext>
      }
    </Fragment>
  );
}
