import { Products } from 'views/products/products';
import { GeneralUpload } from 'views/products/generalUpload'
import { ProductActuals } from 'views/products/ProductActuals';
import { ProductMasterLogs } from 'views/products/ProductMasterLogs';
import { CustomFieldSettings } from 'views/products/CustomFieldSettings';
import { ContentGap } from 'views/products/contentGap';
import { InactiveProducts } from 'views/products/inactiveProducts';

const Routes = [
  {
    path: '/products',
    view: Products,
    layout: 'app',
    permission: 'user',
    title: 'Products',
  },
  {
    path: '/products/inactive',
    view: InactiveProducts,
    layout: 'app',
    permission: 'user',
    title: 'Inactive Products',
  },
  {
    path: '/products/general-upload',
    view: GeneralUpload,
    layout: 'app',
    permission: 'user',
    title: 'General Upload',
  },
  {
    path: '/products/actuals',
    view: ProductActuals,
    layout: 'app',
    permission: 'user',
    title: 'Product Actuals',
  },
  {
    path: '/products/diff',
    view: ContentGap,
    layout: 'app',
    permission: 'user',
    title: 'Content gap',
  },
  {
    path: '/products/history',
    view: ProductMasterLogs,
    layout: 'app',
    permission: 'user',
    title: 'Master Change Log',
  },  
  {
    path: '/products/custom-fields',
    view: CustomFieldSettings,
    layout: 'app',
    permission: 'user',
    title: 'Custom fields settings',
  }
];

export default Routes;
