const TEXT = 'text';
const DATE = 'date';
const DROPDOWN = 'dropdown';
const NUMBER = 'number';

const CUSTOM_COLUMN_TYPE = [
  { value: TEXT, label: 'Text' },
  { value: DATE, label: 'Date' },
  { value: DROPDOWN, label: 'Dropdown' },
  { value: NUMBER, label: 'Number' },
]

export { CUSTOM_COLUMN_TYPE as default, DROPDOWN, TEXT, DATE, NUMBER }