import { Animate, AuthContext, Button, Card, TitleRow, useAPI, ViewContext, Loader } from "components/lib"
import { Fragment, useContext, useEffect, useRef, useState } from "react"

const Reports = () => {
  const [embedUrl, setEmbedUrl] = useState('')
  const [loading, setLoading] = useState(true);

  const getEmbedUrl = useAPI('/api/account/aws-dashboard-id');

  useEffect(() => {
    if (getEmbedUrl.data) {
      setEmbedUrl(getEmbedUrl.data.EmbedUrl)
    }

    if (!getEmbedUrl.loading) {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
      
    }
  }, [getEmbedUrl])

  const hideHeader = () => {
    setTimeout(() => {
      const header = document.getElementById('application-header');

      if (header) {
        header.style.display = 'none';
      }
    }, 10000)
  }
  
  return (
    <Fragment>
      <Animate>

        {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(204, 204, 204, 0.4)",
            height: "100vh",
            zIndex: "1000",
          }}
        >
          <Loader />
        </div>
      )}        
        <Card>
          <div>
            <iframe src={embedUrl} width="100%" height="2400px" frameBorder="0" onLoad={hideHeader} allowFullScreen></iframe>
          </div>
        </Card>
      </Animate>
    </Fragment>
  )
}

export { Reports }