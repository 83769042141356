import React, { Fragment, useEffect, useContext, useState } from 'react';
import { ViewContext, AccountNav, Animate, Card, Form, useAPI,Notification } from 'components/lib';

export function CustomLimits(props) {
  const context = useContext(ViewContext);
  const data = useAPI('/api/user/emails');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (data.data) {
      const tmp = data.data.map((u) => ({ value: u.id, label: u.email }));
      setUsers(tmp);
    }
  }, [data]);

  return (
    <Fragment>
      <Notification />
      <AccountNav />
      <Animate>
        <Card title='Custom Limit' restrictWidth className={props.className}>
          {users.length > 0 ? (
            <Form
              url='/api/user/change-limits'
              method='PUT'
              buttonText='Update Limits'
              resetAfterSuccess
              message="Limits updated"
              data={{
                user: {
                  label: 'User',
                  type: 'select',
                  required: true,
                  options: users,
                },

                high: {
                  label: 'High priority points',
                  type: 'number',
                  required: true,
                },
                medium: {
                  label: 'Medium priority points',
                  type: 'number',
                  required: true,
                },
                low: {
                  label: 'Low priority points',
                  type: 'number',
                  required: true,
                },
                unscanned: {
                  label: 'Unscanned priority points',
                  type: 'number',
                  required: true,
                },
              }}
            />
          ) : (
            ''
          )}
        </Card>
      </Animate>
    </Fragment>
  );
}
