import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Label } from 'components/lib';
import moment from 'moment';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui.min.css';
import 'jquery-ui-dist/jquery-ui.min.js';
import './date.module.scss';
import './asinDatePicker.css'

export const DateInput = ({
    name = 'date-picker',
    value,
    default: defaultVal,
    label = '',
    onChange = () => {} 
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    $(inputRef.current).datepicker('setDate', moment(value).format('YYYY-MM-DD'));
}, [value]);

    function formatDateString(d){
      if (moment(d).toString() === 'Invalid date') {
        return '';
      }
      
      return moment.utc(d).utcOffset(0).format('YYYY-MM-DD') 
    }

  useEffect(() => {
      $(inputRef.current).datepicker({
        dateFormat: 'yy-mm-dd',
        showButtonPanel: true,  
        beforeShow: forEverBtn,
        onSelect: (dateText, inst) => {
          const formattedDate = formatDateString(dateText);
          onChange(name, formattedDate, true);
        }
      });
  }, []);

  function forEverBtn(input, inst) {
    $(document).ready(function() {
      $('.ui-datepicker-current').hide();

      var customButton = $('<button class="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all">Forever</button>');
      $(inst.dpDiv).find('.ui-datepicker-buttonpane').append(customButton);

      customButton.on('click', function() {
        $(inputRef.current).datepicker("setDate", new Date('2099-12-31'));
          onChange(name, '2099-12-31', true);
          handleDateSelect()
      });
    })
  }

const handleDateSelect = (event, ui) => {
  $(inputRef.current).datepicker('hide');
};

return (
    <Fragment>
            { label && 
      <Label text={ label } /> }
      <input type="text" ref={inputRef} value = {formatDateString(value || defaultVal)} />   
    </Fragment>
  )
    

}