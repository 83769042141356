import { Button } from 'components/lib';
import Style from '../table.tailwind.js';

export const CellActions = (props) => {
    const { data: row } = props;

    return (
        <div key={props.rowIndex} className={Style.actions}>
          {props?.custom?.map((action, i) => {
            if (action.condition) {
              return row[action.condition.col] === action.condition.value ? <Button key={i} icon={action.icon} action={() => action.action(row)} className={Style.actionButton} /> : false;
            }
    
            return <Button key={i} icon={action.icon} action={() => action.action(row)} className={Style.actionButton} />;
          })}
    
          {props.editDate && <Button icon='clock' action={() => props.editDate(row)} className={Style.actionButton} />}

          {props.edit && <Button icon='edit' action={() => props.edit(row, (res) => props.callback.edit(res, row))} className={Style.actionButton} />}
    
          {props.download && <Button icon='download' url={props.download} className={Style.actionButton} />}
    
          {props.view && <Button icon='eye' url={`${props.view.url}/${row[props.view.col]}`} className={Style.actionButton} />}
    
          {props.email && <Button icon='mail' action={() => (window.location = `mailto:${row.email}`)} className={Style.actionButton} />}
    
          {props.invite && <Button icon='mail' action={(e) => props.invite(row)} className={Style.actionButton} />}
    
          {props.delete && !props.hideDelete && <Button icon='trash' action={() => props.delete(row, (res) => props.callback.delete(res, row))} className={Style.actionButton} />}
    
          {props.toggleResolve && <Button icon={row.is_resolved_by_user === 'yes' ? 'thumbs-down' : 'thumbs-up'} action={() => props.toggleResolve(row)}  className={Style.actionButton} />}

          {props.updatePosition && (
            <div style={{display: 'inline-block'}}>
              {!props.hideMoveUp && <Button style={{marginTop: '4px'}} icon='chevron-up' action={() => props.updatePosition('up', row)}  className={Style.actionButton} />}
              {!props.hideMoveDown && <Button style={{marginTop: '-8px'}} icon='chevron-down' action={() => props.updatePosition('down', row)}  className={Style.actionButton} />}
            </div>
          )}
        </div>
      )
}