/***
 *
 *   USER
 *   shows the current user
 *   if user belongs to more than one account they can switch accounts here
 *
 **********/

import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ViewContext, HoverNav, Button } from "components/lib";
import Style from "./user.tailwind.js";

export function User(props) {
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (authContext.user?.accounts) {
      let tmpAccounts =
        typeof authContext.user?.accounts === "object"
          ? [authContext.user?.accounts]
          : authContext.user?.accounts;
      setAccounts(tmpAccounts);
    }
  }, [authContext.user?.accounts]);
  const accountName = accounts?.find(
    (x) => x.id === authContext.user?.account_id
  )?.name;

  return (
    <div className={Style.user}>
      <div className={Style.name}>Welcome, {authContext.user?.name}</div>

      {accounts?.length > 1 && (
        <HoverNav
          dark
          icon="user"
          label={accountName}
          className={Style.hoverNav}
          align="right"
        >
          {accounts.map((account) => {
            return (
              <Button
                key={account.id}
                text={account.name}
                action={() => {
                  viewContext.setLoading(true);
                  authContext.switchAccount(account.id);
                }}
              />
            );
          })}
        </HoverNav>
      )}
    </div>
  );
}
