import { Fragment, useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Card, ProductNav, GridTable, Button, Animate, TitleRow, useAPI, Message, ViewContext } from 'components/lib';
import colDefs from './productColDef';

const ProductMasterLogs = () => {
    const gridRef = useRef();
    
    const data = useAPI('/api/products/logs-for-master');
    const [logs, setLogs] = useState([]);
    const [message, setMessage] = useState('');
    const context = useContext(ViewContext);
  
    useEffect(() => {
      if (!data.loading && data?.data?.length === 0) {
        setMessage('No logs yet!')
      }

      if (data?.data) {
        const formattedLogs = data.data.map((d, index) => {
          const [,column_name] = d.column_name.split('|')
          return { ...d, column_name, id: `productHistory-${index + 1}` }
        })        
        setLogs(formattedLogs);      
      }
    }, [data?.data]);


    const downloadCSV = useCallback(() => {
      gridRef.current.api.exportDataAsExcel({
        fileName: 'history',
        sheetName: 'history',
        allColumns: true
      });
  }, [data]);

  async function copy(text) {
    try {
      await navigator.clipboard.writeText(text);
      context.notification.show(`copied to clipboard!`, 'success', true);
    } catch (err) {
      console.error('Could not copy text to clipboard:', err);
    }
  }

    return (<div>
        <Fragment>
          <ProductNav />
          <Animate>
          {message && <Message
            text={message}
            type='info'
          />}
            <TitleRow title='Master Change Log'>
            <div style={{ display: 'flex' }}>
            <Button small text='Download' action={downloadCSV} />
            </div>
          </TitleRow>
            <Card>
              <div style={{display: 'flex', justifyContent: 'center'}}>
              <GridTable
                gridRef={gridRef}
                width='100%'
                columnDefs={[
                  colDefs.market,
                  {
                    ...colDefs.asin,
                    cellRendererParams : {...colDefs.asin.cellRendererParams, copy }
                  },
                  colDefs.column_name,
                  colDefs.old_value,
                  colDefs.new_value,
                  colDefs.created_by,
                  colDefs.created_at,
                 // colDefs.master_account_id
                ]}
                defaultColDef = {{
                  //flex: 1,
                  filter: true,
                  floatingFilter:true,
                  resizable: true,
                  filterParams: {
                    excelMode: 'windows',
                },
                }}
                rowData={logs}
                loading={data.loading}
              />
              </div>
            </Card>
          </Animate>
        </Fragment>
      </div>)
}

export { ProductMasterLogs }