import Style from '../table.tailwind.js';
import { Button } from "components/lib";

export const CellLink = ({linkMap, value, data, copyToClipboard = false, copy = () => {}}) => {
    return (
        <div className='flex space-x-6 items-end'>
          <a href={`${linkMap[data.market]}/dp/${value}`} target='_blank'>
            {value}
          </a>
          {copyToClipboard && <Button icon='copy' action={() => copy(value)} className={Style.actionButton} />}
        </div>
    );
}