import React, { Fragment, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { ViewContext, Card, ProductIssueNav, GridTable, Button, Animate, TitleRow, useAPI, CellActions, AuthContext, RichTextEditor, ModalWithoutContext } from 'components/lib';
import { useLocation } from 'react-router-dom';
import colDefs, { dropDown }  from '../products/productColDef'
import { GeneralUpload } from 'views/products/generalUpload';
import axios from 'axios';
import moment from 'moment';
import { ACTIVE } from 'utils/status';
import { DROPDOWN } from 'utils/customColumnTypes';
import Style from 'components/form/form.tailwind';

const RICH_COLS = ['description_master', 'notes_master', 'title_master', 'bullet_1_master', 'bullet_2_master', 'bullet_3_master', 'bullet_4_master', 'bullet_5_master'];
const IMMUTABLE_COLS = ['code', 'issue_status', 'market', 'first_scan', 'last_scan'];

export function ProductsFindings(props) {
  const [refreshToken, setRefreshToken] = useState(+new Date());
  const context = useContext(ViewContext);
  const gridRef = useRef();
  const location = useLocation()
  const locationParts = location.pathname.split('/');
  const status = locationParts[locationParts.length -1] === 'dashboard' ? 'issues' : locationParts[locationParts.length -1]
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [colId, setColId] = useState(null);
  const [oldValueOfSelectedCell, setOldValueOfSelectedCell] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedModalValue, setSelectedModalValue] = useState('');


  const data = useAPI(`/api/product-issue?limit=${props.showFirst100 ? '100' : '100000'}&status=${status}`, null, refreshToken);
  const colData = useAPI(`/api/custom-fields/active?table=issue`);

  const [productIssues, setProductIssues] = useState([]);
  const [customFieldColumns, setCustomFieldColumns] = useState([]);
  const [generalUploadModal, setGeneralUploadModal] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (data?.data) {
      setProductIssues(data.data.map((d, index) => ({...d, id: `productsFindings-${index + 1}`})));
    }
  }, [data?.data]);

  useEffect(() => {
    let tmp = [];
    let alreadyHasMarketCol = false;
    let alreadyHasPausedUntilCol = false;
    if (colData?.data?.length) {
      colData?.data?.forEach(cusCol => {
        if (!alreadyHasMarketCol && cusCol.column_name === 'market') {
          alreadyHasMarketCol = true;
        }
  
        if (!alreadyHasPausedUntilCol && cusCol.column_name === 'issue_paused_until') {
          alreadyHasPausedUntilCol = true;
        }
  
        let defaults = colDefs[cusCol.column_name];
        if (cusCol.column_type === DROPDOWN && cusCol.category === 'custom') {
          defaults = {
            ...dropDown, 
            cellEditorParams: {
              ...dropDown.cellEditorParams,
              values: cusCol.column_allowed_options.split(',')
            }
          }
        } else if (RICH_COLS.includes(cusCol.column_name)) {
          defaults = {
            cellRenderer: (params) => <RichTextEditor disabled={true} value={params.value} />,
            editable: false
          }
        } else if (cusCol.column_name === 'asin') {
          defaults = {
            ...colDefs[cusCol.column_name],
            cellRendererParams : {...colDefs[cusCol.column_name].cellRendererParams, copy }
          }
        }
  
        if (cusCol.column_name === 'paused_until') {
          return
        }
  
        tmp.push({
          field: cusCol.column_name, 
          sortable: true, 
          editable: IMMUTABLE_COLS.includes(cusCol.column_name) ? false : authContext.permission?.editor, 
          minWidth:150,
          headerRealName: cusCol.column_label, 
          headerName: cusCol.column_label, 
          ...defaults,
        })
      })
  
      if (!alreadyHasMarketCol) {
        tmp.push({ ...colDefs.market, hide: true })
      } else {
        const marketIndex = tmp.findIndex(t => t.field === 'market')
        const marketTmp = { ...tmp[marketIndex], hide: false };
        tmp.splice(marketIndex, 1, marketTmp)
      }
  
      if (!alreadyHasPausedUntilCol) {
        tmp.push({ ...colDefs.issue_paused_until, hide: true })   
      }
  
      setCustomFieldColumns(tmp) 
    }
  }, [colData])

  async function copy(text) {
    try {
      await navigator.clipboard.writeText(text);
      context.notification.show(`copied to clipboard!`, 'success', true);
    } catch (err) {
      console.error('Could not copy text to clipboard:', err);
    }
  }
/**
 * If resoved_by_user is yes and we update the pause date then
 * issue won't be removed from resolved page instead only
 * issue_status and issue_paused_until will be updated
 * 
 */

  function editPauseDate (data) {
      context.modal.show(
        {
          title: 'Update Product',
          form: {
            id: {
              type: 'hidden',
              value: data.productIssueId,
            },
            issue_paused_until: {
              label: colDefs.issue_paused_until.headerName,
              type: 'date',
              default: data.issue_paused_until
            },
          },
          buttonText: 'Save',
          url: '/api/product-issue',
          method: 'PATCH',
        },
        (form, res) => {
          const rowNode = gridApi.getRowNode(data.id);
          if (rowNode) {
            if (status === 'issues' && moment(form.issue_paused_until.value) > moment()) {
              gridApi.updateRowData({remove: [rowNode.data]});
            } else if (status === 'paused' && moment(form.issue_paused_until.value) < moment()) {
              gridApi.updateRowData({remove: [rowNode.data]});
            }  else if (status === 'resolved' && moment(form.issue_paused_until.value) < moment()) {
              gridApi.updateRowData({update: [{...rowNode.data, issue_status: 'active'}]});
            }  else if (status === 'resolved' && moment(form.issue_paused_until.value) > moment()) {
              gridApi.updateRowData({update: [{...rowNode.data, issue_status: 'paused'}]});
            }                 
          }

          context.notification.show(data.asin + ' was updated', 'success');
        }
      );
    }

  function toggleResolve(data) {
    context.modal.show(
      {
        title: `Are you sure you want to mark it as ${data.resolved_at ? 'unresolved' : 'resolved'}?`,
        form: {
          id: {
            type: 'hidden',
            value: data.productIssueId,
          },
          status: {
            type: 'hidden',
            value: ACTIVE
          }
        },
        buttonText: 'Confirm',
        url: '/api/product-issue/toggle-resolve-status',
        method: 'PUT',
      },
      () => {
        if (props.updateStats) {
          props.updateStats()
        }

        const rowNode = gridApi.getRowNode(data.id);

        if(rowNode) {
          gridApi.updateRowData({remove: [rowNode.data]});
        }
        
      }
    );
  }
  
  const downloadExcel = useCallback(() => {
      gridRef.current.api.exportDataAsExcel({
        fileName: 'Products issues',
        sheetName: 'Products issues',
        allColumns: true
        });      
  }, [productIssues]);

  function toggleUploadProcess() {
    if (generalUploadModal) {
      setRefreshToken(+new Date());
    }
      
    setGeneralUploadModal(!generalUploadModal)
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleChange = async (row) => {
    console.log({ new: row.newValue, odl: row.oldValue });
    const isIssueCol = ['issue_paused_until', 'comment'].includes(row.colDef.field) ? true : false;
    const key = isIssueCol ? row.colDef.field : row.colDef.headerName;
    const id = isIssueCol ? row.data.productIssueId : row.data.productId;
    const url = isIssueCol ? '/api/product-issue' : '/api/products';

    const data = { [key]: row.newValue, id, isCustomCol: row.colDef.isCustomCol }
    delete data.productId;

    try {
      const res = await axios({
        url,
        method: 'patch',
        data
      })
  
      const invalidValues = res?.data?.data?.invalidValues;
  
      const invalidKeys = invalidValues ? Object.keys(invalidValues) : [];
  
      if(invalidKeys.length > 0){
        const messageStr = `${invalidValues[invalidKeys[0]]} is not a valid value for ${row.colDef.headerName}`;
        throw new Error(messageStr)
      } else {
        const messageStr = `${row.colDef.headerName} updated successfully!`;
        let productWithNewValue = { ...row.data, [row.colDef.field]: row.newValue};

        const rowNode = gridApi.getRowNode(productWithNewValue.id);
        if (rowNode) {
          if (row.colDef.field === 'issue_paused_until') {
            if (status === 'issues' && moment(row.newValue) > moment()) {
              gridApi.updateRowData({remove: [rowNode.data]});
            } else if (status === 'paused' && moment(row.newValue) < moment()) {
              gridApi.updateRowData({remove: [rowNode.data]});
            }  else if (status === 'resolved' && moment(row.newValue) < moment()) {
              gridApi.updateRowData({update: [{...rowNode.data, issue_status: 'active'}]});
            }  else if (status === 'resolved' && moment(row.newValue) > moment()) {
              gridApi.updateRowData({update: [{...rowNode.data, issue_status: 'paused'}]});
            } else if (!row.newValue) {
              gridApi.updateRowData({update: [{...rowNode.data, issue_status: 'active'}]});              
            }
          } else {
            gridApi.updateRowData({update: [productWithNewValue]});
          }          
        }

        context.notification.show(messageStr, 'success', true);    
      }
    } catch (error) {
      const productWithOldValue = { ...row.data, [row.colDef.field]: row.oldValue};
      context.notification.show(error?.response?.data?.message || error.message, 'error', true);
      gridApi.updateRowData({update: [productWithOldValue]});
    }
  }

  const handleCellClicked= (params) => {
    if (RICH_COLS.includes(params.colDef.field)) {
      setRowData(params.data);
      setColId({headerName: params.colDef.headerName, field: params.colDef.field});
      setSelectedModalValue(params.value)
      setOldValueOfSelectedCell(params.value)
      setShowModal(true);
    }
  };

  const handleSave = () => {
    const updatedRowData = { ...rowData, [colId.field]: selectedModalValue };
    gridApi.updateRowData({update: [updatedRowData]});
    
    if (oldValueOfSelectedCell !== selectedModalValue) {
      handleChange({
        data: updatedRowData,
        colDef: { headerName: colId.headerName },
        newValue: selectedModalValue,
      }); 
    }

    handleCloseModal();
  };

  const handleCloseModal = e => {
    setRowData(null);
    setColId(null);
    setOldValueOfSelectedCell(null);
    setShowModal(false);
  }

  return (
    <Fragment>
      {!props.hideNav && <ProductIssueNav />}
      {generalUploadModal && <GeneralUpload handleCloseModal={toggleUploadProcess}/>}
      <Animate>
        
          <TitleRow title='Unresolved Issues'>          
          { !props.hideUploadBtns && authContext.permission?.editor && ( 
            <Button small text='Upload' color='blue' action={toggleUploadProcess} style={{ marginRight: '10px' }} /> 
          )}
          
          <Button small text='Download' action={downloadExcel} />
          </TitleRow>

        <Card name='unresolvedIssues' title=''>
        <div style={{display: 'flex', justifyContent: 'center'}}>

          <GridTable
             gridRef={gridRef}
             width='100%'
             onGridReady={onGridReady}
             gridApi={gridApi}
             columnDefs={[
              ...customFieldColumns,
              colDefs.is_resolved_by_user,            
              {
                 headerName: 'Actions',
                 cellRenderer: CellActions,
                 sortable:false,
                 filter:false,
                 minWidth:100,
                 cellRendererParams: {
                   toggleResolve,
                   editDate: editPauseDate
                 }
               }
             ]}
             defaultColDef = {{
              flex: 1,
              filter: true,
              filterParams: {
                excelMode: 'windows',
              },
              floatingFilter:true,
              resizable: true,
              onCellValueChanged: handleChange,
              onCellDoubleClicked: handleCellClicked
            }}
             rowData={productIssues}
             loading={data.loading}
          />
        </div>
        </Card>
        
      </Animate>
      {showModal && <ModalWithoutContext onClose={handleCloseModal} >
        <Card style={{marginBottom: '1.5rem'}}>
          <RichTextEditor disabled={!showModal} value={selectedModalValue} onChange={text => setSelectedModalValue(text)}/>
          
          <div>
            <Button color='green' text='Update' action={ handleSave } className={ Style.button } />
            <Button color='red' text='Cancel' action={ handleCloseModal } className={ Style.button } />
          </div>
        </Card>
          
        </ModalWithoutContext>
      }
    </Fragment>
  );
}
