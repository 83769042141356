/***
 *
 *   PRIVACY POLICY
 *   Boilerplate privacy statement – replace the values
 *   inside [] with your own text
 *
 **********/

import React from 'react';
import { Article, List, useAPI } from 'components/lib';
import ManualForAuthorizeUser from './manualForAuthorizeUser';
import ManualForUnauthorizeUser from './manualForUnauthorizeUser';

export function Manual(props) {
  const user = useAPI('/api/user', '', '', false);
  return <Article>{!user.loading && (user?.data?.id ? <ManualForAuthorizeUser /> : <ManualForUnauthorizeUser />)}</Article>;
}
