import React, { Fragment, useState, useEffect } from 'react';
import { Card, AccountNav, Row, Button, Animate, TitleRow, useAPI } from 'components/lib';
import moment from 'moment';
import { saveAs } from 'file-saver';

export const Audit = () => {
  const data = useAPI('/api/audit');

  const download = (file) => {
    saveAs(file.url, moment(file.created_at).format('LL') + '.csv');
    return false;
  };

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card title='Audit Files' restrictWidth center>
          {data?.data?.map((file) => (
            <Row key={`file-${file.id}`}>
              <Button action={() => download(file)} text={moment(file.created_at).format('LL')} />
            </Row>
          ))}
        </Card>
      </Animate>
    </Fragment>
  );
};
