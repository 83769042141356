/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext, useState } from 'react';
import { AuthContext, Onboarding, Form, Message, useNavigate } from 'components/lib';
import { MARKETS } from '../../utils/constants'

export function OnboardingView(props){

  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const views = []

  if (context.permission.editor){
    views.push({

      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome/>
  
    })

    views.push({

      name: 'Add products', 
      description: 'Let\'s start adding products in your ASINRx account.',
      component: <AddProduct context={context} />,
      
    });
  } else {
    navigate('/dashboard');
  }

  return <Onboarding save onFinish='/dashboard' views={ views }/>

}

function Welcome(){

  return (
    <Fragment>

      <p>Getting started is as simple 1-2-3:<br />
      
      1. Load your active ASINs<br />2. ASINRx will email you once the first scan is complete.<br />3. Review the results!<br />
        
      <strong>On the next page you can get started by pasting in a list of active asins! 🛠 </strong></p>

    </Fragment>
  )
}

function AddProduct(props){
  const [formMessage, setFormMessage] = useState({text: `Please see the downloaded for product not uploaded.`, type: 'success'})
  
  const handleResponse = response => {
  const res = response?.data?.data || {}

  let data = '';

  const remainingProductsArr = [].concat.apply([], Object.values(res.remainingProducts));
  const remainingProductsStr = remainingProductsArr.map((a) => a.asin).join('\n');
  data = data + remainingProductsStr;

  const existingProductsArr = res.existingProducts ? [].concat.apply([], Object.values(res.existingProducts)) : [];

  if (existingProductsArr.length > 0) {
    const existingProductsStr = existingProductsArr.join('\n');
    const sectionB = ['\n' + '\n' + 'Existing Products'] + '\n' + existingProductsStr;
    data = data + sectionB;
  }

  const invalidProductsArr = res.invalidProducts ? [].concat.apply([], Object.values(res.invalidProducts)) : [];

  if (invalidProductsArr.length > 0) {
    const invalidProductsStr = invalidProductsArr.map((a) => a.asin).join('\n');
    const sectionC = ['\n' + '\n' + 'Invalid Products'] + '\n' + invalidProductsStr;
    data = data + sectionC;
  }

  download('text/plain', data, 'ASINRX-left out products.txt');
}


function download(type, data, filename) {
  if (data.length > 0) {
    setFormMessage({text: `Please see the downloaded ${filename} for product not uploaded.`, type: 'error'});

    const element = document.createElement('a');
    element.id = +new Date();
    const file = new Blob([data], { type, endings: 'native' });

    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    element.outerHTML = '';
  } else {
    setFormMessage({text: `Products added successfully.`, type: 'success'});
  }
}
  return (
    <Form 
      title = 'Add Product'
      startTextList = {['Here you can add multiple products by placing one ASIN in one line in the text area', 'select the market for all products', 'click "Add" button']}  
      data={{
        asins: {
          label: 'ASIN',
          type: 'textarea',
          required: true,
        },
        market: {
          label: 'Market',
          type: 'select',
          options: MARKETS,
          required: true,        
        },
      }}
      message={formMessage}
      buttonText = 'Add'
      url = '/api/products/manual-input'
      method = 'POST'
      callback = { handleResponse }
      resetAfterSuccess
    />
  )
}