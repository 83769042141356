const FORECAST = "forecast";
const SALES = "sales";
const CATALOG = "catalog";
const TRAFFIC = "traffic";
const INVENTORY = "inventory";

const KEY_FILE_TYPES = [
    {value: FORECAST, label: 'Forecast'},
    {value: SALES, label: 'Sales'},
    {value: CATALOG, label: 'Catalog'},
    {value: TRAFFIC, label: 'Traffic'},
    {value: INVENTORY, label: 'Inventory'}
];

export { FORECAST, KEY_FILE_TYPES };
