import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

 function RichTextEditor({ value = '', onChange = () => {}, disabled = false}) {
  const editorRef = useRef(null);

  return (
    <>
      <Editor
        apiKey='fiejsw8900gc8aqeha6dpyuofxmuzwwvo3dnyo9nw14a8abc'
        inline = {disabled}
        disabled= {disabled}
        onInit={(evt, editor) => editorRef.current = editor}
        value={value}
        init={{
          menubar: !disabled,

          plugins: [ 'lists' ],
          toolbar: 'paragraph bold italic bullist numlist linebreak'
        }}
        height='500px'
        width = '400px'
        onEditorChange = {onChange}
      />
    </>  
  );
}

export { RichTextEditor }