import { Badge } from 'components/lib';
import Style from '../table.tailwind.js';

export const CellBadge = ({value, mapping, data, badgeFor}) => {
    if (badgeFor === 'status') {
        return <Badge text={value} color={mapping[value]} className={Style.badge} />
    }

    return <Badge text={value} color={mapping[data.severity]} className={Style.badge} />
}
