import { useState, useRef, useEffect } from 'react';
import { Animate, Card, useAPI, GridTable, CellActions, ProductIssueNav } from "components/lib";
import { ACTIVE, INACTIVE } from 'utils/status';
import colDefs from '../products/productColDef'
import axios from 'axios';

const CustomFieldSettings = () => {
  const gridRef = useRef();
  const [refreshToken, setRefreshToken] = useState(+new Date());

  const data = useAPI('/api/custom-fields?table=issue', null, refreshToken);
  const [customColumns, setCustomColumns] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setCustomColumns(data?.data);      
    }
  }, [data?.data]);

  const handleChange = async (row) => {
    let isValid = true;

    if(row.colDef.field === 'status_in_issue_table') {
      isValid = row.newValue === ACTIVE || row.newValue === INACTIVE
    }

    if (isValid) {
      const data = { [row.colDef.field]: row.newValue, id: row.data.id, table: 'issue' }  

      const res = await axios({
        url: '/api/custom-fields',
        method: 'patch',
        data
      })

      if (row.colDef.field === 'position_in_issue_table' && res.status === 200) {
        setRefreshToken(+new Date)
      }

    }
  }

  const updatePosition = async (direction, row) => {
    const updatingRowIndex = customColumns.findIndex(a => a.position_in_issue_table === row.position_in_issue_table)
    let newValue = row.position_in_issue_table + 1;

    if (direction === 'up') {
      newValue = row.position_in_issue_table - 1;
    }
    const indexForNextUpdatingRow = customColumns.findIndex(a => a.position_in_issue_table === newValue)

    const firstUpdatingRow = {...customColumns[updatingRowIndex], position_in_issue_table: newValue}
    const secondUpdatingRow = {...customColumns[indexForNextUpdatingRow], position_in_issue_table: row.position_in_issue_table}
    const tmpCustomColumns = [...customColumns];
    tmpCustomColumns[updatingRowIndex] = secondUpdatingRow;
    tmpCustomColumns[indexForNextUpdatingRow] = firstUpdatingRow;
    
    const data = {position_in_issue_table: newValue, id: row.id, table: 'issue'}
    const res = await axios({
      url: '/api/custom-fields',
      method: 'patch',
      data
    })
    
    if (res.status === 200) {
      setCustomColumns( tmpCustomColumns )      
    }
  }

  const isFirstElement = pos => pos === 1;
  const isLastElement = pos => pos === Math.max(...customColumns.map(o => o.position_in_issue_table))

  return (
    <div>
      <ProductIssueNav />
      <Animate>

        <Card name='Custom field settings'>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <GridTable
            gridRef={gridRef}
            width='1200px'
            columnDefs={[
              {
                ...colDefs.column_label,
                editable: p => ['code', 'issue_paused_until', 'paused_until', 'issue_status'].includes(p.data.column_name) ? false : p.data.canDisable,
              },              
              colDefs.column_type,
              colDefs.column_allowed_options,
              {
                ...colDefs.position_in_issue_table,
                cellEditorParams: {
                  ...colDefs.position_in_issue_table.cellEditorParams,
                  values: customColumns.map(x => x.position_in_issue_table)
              }
              },
              {
                ...colDefs.status_in_issue_table,
                editable: p => ['code', 'title_master'].includes(p.data.column_name) ? false : p.data.canDisable,
              },
              {
                headerName: 'Actions',
                cellRenderer: p => <CellActions {...p} hideMoveUp={isFirstElement(p.data.position_in_issue_table)} hideMoveDown={isLastElement(p.data.position_in_issue_table)} />,
                cellRendererParams: {
                  updatePosition
                },
                maxWidth: 100,
                filter:false
              }
            ]}
            defaultColDef = {{
              flex: 1,
              filter: true,
              filterParams: {
                excelMode: 'windows',
            },
              floatingFilter:true,
              resizable: true,
              onCellValueChanged: handleChange
            }}

            rowData={customColumns}
            loading={data.loading}
          />
          </div>
        </Card>
      </Animate>      
    </div>
  )
};

export { CustomFieldSettings }