import ReactStars from "react-stars";

const Stars = ({
  count = 5,
  ratingChanged = () => {},
  color1 = "grey",
  color2 = "grey",
  size = 14,
  edit = false,
}) => (
  <ReactStars
    count={count}
    onChange={ratingChanged}
    size={size}
    color1={color1}
    color2={color2}
    edit={edit}
  />
);

export { Stars };
