import React, { Fragment, useContext, useRef, useState } from 'react';
import { Card, AccountNav, Row, Button, Animate, TitleRow, useAPI, ViewContext, GridTable } from 'components/lib';
import { FORECAST, KEY_FILE_TYPES } from 'utils/keyFileTypes';
import moment from 'moment';

export const KeyFiles = () => {
  const context = useContext(ViewContext);
  const gridRef = useRef();
  const [refreshToken, setRefreshToken] = useState(+new Date());

  const data = useAPI('/api/audit/keyFiles', null, refreshToken);

  function initiateUploadProcess() {
    context.modal.show(
      {
        title: 'Upload File',
        form: {
          jsonFile: {
            type: 'file',
            accept: ['csv', 'xls', 'txt', 'xlsx'],
            required: true
          },
          file_type: {
            label: 'Type',
            type: 'select',
            default: FORECAST,
            options: KEY_FILE_TYPES,
          },
        },
        buttonText: 'Add',
        text: '',
        url: '/api/audit/upload-key-files',
        method: 'POST',
      },
      () => {
        setRefreshToken(+new Date());
      }
    );
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <TitleRow>
          <Button small text='Upload File' action={initiateUploadProcess} style={{ marginRight: '10px' }} />          
        </TitleRow>

        <Card title='Key Files Page'>
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <GridTable
            gridRef={gridRef}
            width='1200px'
            columnDefs={[
              {
                field: 'email', 
                sortable: true, 
                headerName: 'Email', 
                minWidth: 250
              },
              {
                field: 'name', 
                sortable: true, 
                headerName: 'File name', 
                minWidth: 250
              },
              {
                field: 'url', 
                sortable: true, 
                headerName: 'S3 key', 
                minWidth: 250
              },
              {
                field: 'file_type', 
                sortable: true, 
                headerName: 'File type', 
                valueFormatter: p => KEY_FILE_TYPES.find(a => a.value === p.value).label,
                minWidth: 150
              },
              {
                field: 'created_at', 
                sortable: true, 
                headerName: 'Uploaded at', 
                valueFormatter: p => p.value ? moment(p.value).format('YYYY-MM-DD') : '',
                minWidth: 249
              }
            ]}
            defaultColDef = {{
              filter: true,
              filterParams: {
                excelMode: 'windows',
            },
              floatingFilter:true,
              
              resizable: true
            }}
            rowData={data?.data || []}
            loading={data.loading}
          />        
          </div>
          </Card>
      </Animate>
    </Fragment>
  );
};
