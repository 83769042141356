import { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { Animate, ProductNav, TitleRow, Button, Card, Form, ModalWithoutContext, ViewContext, useAPI, GridTable, CellActions } from "components/lib";
import CustomColumnTypes, { DROPDOWN } from 'utils/customColumnTypes';
import STATUSES, { ACTIVE, INACTIVE } from 'utils/status';
import Style from 'components/feedback/feedback.tailwind';
import colDefs from './productColDef'
import axios from 'axios';

const CustomFieldSettings = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isValueRequired, setIsValueRequired] = useState(false);
  const [form, setForm] = useState({ column_label: '', column_type: '', column_allowed_options: '', status_in_product_table: ACTIVE })
  const context = useContext(ViewContext);
  const gridRef = useRef();
  const [refreshToken, setRefreshToken] = useState(+new Date());

  const data = useAPI('/api/custom-fields', null, refreshToken);
  const [customColumns, setCustomColumns] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setCustomColumns(data?.data.filter(a => a.category !== 'finding'));      
    }
  }, [data?.data]);

  const addNewField = () => {
    setForm({ column_label: '', column_type: '', column_allowed_options: '', status_in_product_table: ACTIVE })
    setOpenModal(true)
  };

  const handleCloseModal = () => setOpenModal(false)

  const handleChange = async (row) => {
    let isValid = true;

    if(row.colDef.field === 'status_in_product_table') {
      isValid = row.newValue === ACTIVE || row.newValue === INACTIVE
    }

    if (isValid) {
      const data = { [row.colDef.field]: row.newValue, id: row.data.id }  

      const res = await axios({
        url: '/api/custom-fields',
        method: 'patch',
        data
      })

      if (row.colDef.field === 'position_in_product_table' && res.status === 200) {
        setRefreshToken(+new Date)
      }

    }
  }

  function deleteCustomField(data) {
    context.modal.show(
      {
        title: 'Delete Custom field',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
        },
        buttonText: 'Delete',
        text: `Are you sure you want to delete ${data.column_label}?`,
        url: '/api/custom-fields',
        method: 'DELETE',
        destructive: true,
      },
      () => {
        handleCloseModal();
        setRefreshToken(+new Date());
        context.notification.show(data.column_label + ' was deleted', 'success', true);
      }
    );
  }

  const updatePosition = async (direction, row) => {
    const updatingRowIndex = customColumns.findIndex(a => a.position_in_product_table === row.position_in_product_table)
    let newValue = row.position_in_product_table + 1;

    if (direction === 'up') {
      newValue = row.position_in_product_table - 1;
    }
    const indexForNextUpdatingRow = customColumns.findIndex(a => a.position_in_product_table === newValue)

    const firstUpdatingRow = {...customColumns[updatingRowIndex], position_in_product_table: newValue}
    const secondUpdatingRow = {...customColumns[indexForNextUpdatingRow], position_in_product_table: row.position_in_product_table}
    const tmpCustomColumns = [...customColumns];
    tmpCustomColumns[updatingRowIndex] = secondUpdatingRow;
    tmpCustomColumns[indexForNextUpdatingRow] = firstUpdatingRow;
    
    const data = {position_in_product_table: newValue, id: row.id}
    const res = await axios({
      url: '/api/custom-fields',
      method: 'patch',
      data
    })
    
    if (res.status === 200) {
      setCustomColumns( tmpCustomColumns )      
    }
  }

  const isFirstElement = pos => pos === 1;
  const isLastElement = pos => pos === Math.max(...customColumns.map(o => o.position_in_product_table))
  const hideDelete = row => row.category === 'standard' || row.category === 'master' || row.category === 'finding'

  return (
    <div>
      <ProductNav />
      <Animate>
        {openModal && <ModalWithoutContext onClose={handleCloseModal} title='Add new custom field'>
          <Form 
            data={{
                column_label: {
                  label: 'Column name',
                  type: 'text',
                  required: true,
                  value: form.column_label
                },
                column_type: {
                  label: 'Column type',
                  type: 'select',
                  options: CustomColumnTypes,
                  value: form.column_type,
                  required: true,
                },
                column_allowed_options: {
                  label: 'Column Value',
                  type: isValueRequired ? 'text' : 'hidden',
                  value: form.column_allowed_options,
                  required: isValueRequired,
                },
                status_in_product_table: {
                label: 'Column Status',
                type: 'hidden',
                default: ACTIVE,
              },
              position_in_product_table: {
                label: 'Column Position in table',
                default: Math.max(...customColumns.map(o => o.position_in_product_table)) + 1,
                type: 'hidden'
              },
              position_in_issue_table: {
                label: 'Column Position in Issue table',
                default: Math.max(...data?.data.map(o => o.position_in_issue_table)) + 1,
                type: 'hidden'
              }
            }}
            method='POST'
            url='/api/custom-fields'
            updateOnChange
            resetAfterSuccess
            className={ Style.form }
            buttonText='Add'
            cancel={handleCloseModal}
            onChange={ udata =>  {
              if(udata.input === 'column_type'){
                setIsValueRequired(udata.value === DROPDOWN)
              }
              
              setForm({
                ...form,
                [udata.input]: udata.value
              })                  
            }}
            callback={ e => {
              handleCloseModal()
              setRefreshToken(+new Date());
            }}
            />          
        </ModalWithoutContext>}
        <TitleRow title='Add new field'>
        
        <Button small text='Add new field' action={addNewField} />
        </TitleRow>

        <Card name='Custom field settings'>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <GridTable
            gridRef={gridRef}
            width='1200px'
            columnDefs={[
              {
                ...colDefs.column_label,
                editable: p => ['paused_until', 'status'].includes(p.data.column_name) ? false : p.data.canDisable,
              },
              colDefs.column_type,
              colDefs.column_allowed_options,
              {
                ...colDefs.position_in_product_table,
                cellEditorParams: {
                  ...colDefs.position_in_product_table.cellEditorParams,
                  values: customColumns.map(x => x.position_in_product_table)
              }
              },
              {
                ...colDefs.status_in_product_table,
                editable: p => p.data.canDisable
              }, // if column is master then hide delete
              {
                headerName: 'Actions',
                cellRenderer: p => <CellActions {...p} hideDelete={hideDelete(p.data)} hideMoveUp={isFirstElement(p.data.position_in_product_table)} hideMoveDown={isLastElement(p.data.position_in_product_table)} />,
                cellRendererParams: {
                  delete: deleteCustomField,
                  updatePosition
                },
                maxWidth: 100,
                filter:false
              }
            ]}
            defaultColDef = {{
              flex: 1,
              filter: true,
              filterParams: {
                excelMode: 'windows',
            },
              floatingFilter:true,
              resizable: true,
              onCellValueChanged: handleChange
            }}

            rowData={customColumns}
            loading={data.loading}
          />
          </div>
        </Card>
      </Animate>      
    </div>
  )
};

export { CustomFieldSettings }