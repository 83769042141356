import { Account } from 'views/account';
import { Profile } from 'views/account/profile';
import { Billing } from 'views/account/billing';
import { Upgrade } from 'views/account/upgrade';
import { Password } from 'views/account/password';
import { TwoFA } from 'views/account/2fa';
import { Audit } from 'views/account/audit';
import { KeyFiles } from 'views/account/keyFiles';
import { CustomLimits } from 'views/account/customLimit';
import { Users } from "views/account/users";
import { ProductIssueCode } from 'views/account/productIssueCode';
import { PlanUsage } from 'views/account/planUsage';
import { Reports } from 'views/account/reports';
import { OtherUsers } from 'views/account/otherUsers';

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Your Account',
  },
  {
    path: '/account/profile',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile',
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Change Password',
  },
  {
    path: '/account/2fa',
    view: TwoFA,
    layout: 'app',
    permission: 'user',
    title: 'Manage 2FA',
  },
  {
    path: '/account/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    title: 'Billing',
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'owner',
    title: 'Upgrade',
  },
  {
    path: '/account/custom-limits',
    view: CustomLimits,
    layout: 'app',
    permission: 'master',
    title: 'Custom Limits',
  },
  {
    path: '/account/audit',
    view: Audit,
    layout: 'app',
    permission: 'user',
    title: 'Audit FIles',
  },
  {
    path: '/account/key-files',
    view: KeyFiles,
    layout: 'app',
    permission: 'user',
    title: 'Key Files Page',
  },
  {
    path: "/account/users",
    view: Users,
    layout: "app",
    permission: "editor",
    title: "Users",
  },
  {
    path: "/account/other-users",
    view: OtherUsers,
    layout: "app",
    permission: "master",
    title: "Manage other users",
  },
  {
    path: '/account/manage-codes',
    view: ProductIssueCode,
    layout: 'app',
    permission: 'master',
    title: 'Manage issue codes',
  },
  {
    path: '/account/plan-usage',
    view: PlanUsage,
    layout: 'app',
    permission: 'user',
    title: 'Plan Usage',
  },
  {
    path: '/account/manage-reports',
    view: Reports,
    layout: 'app',
    permission: 'master',
    title: 'Manage tabs access',
  }
];

export default Routes;
