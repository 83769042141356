import React, { Fragment, useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ViewContext, Card, ProductNav, GridTable, Button, Animate, TitleRow, useAPI, Message, CellActions } from 'components/lib';
import colDefs from './productColDef'

const ACTUAL_COLS = [
  colDefs.title_actual,
  colDefs.brand_actual,
  colDefs.scan_count,
  colDefs.variation_parent_group_actual,
  colDefs.bullet_1_actual,
  colDefs.bullet_2_actual,
  colDefs.bullet_3_actual,
  colDefs.bullet_4_actual,
  colDefs.bullet_5_actual,
  colDefs.description_actual,
  colDefs.list_price_actual,
  colDefs.style_actual,
  colDefs.size_actual,
  colDefs.color_actual,
  colDefs.part_number_actual,
  colDefs.model_number_actual,
  colDefs.number_of_items_actual,
  colDefs.browse_node_actual,
  colDefs.category_actual,
  colDefs.has_aplus_actual,
  colDefs.number_of_bullet_points_actual,
  colDefs.number_of_images_actual,
  colDefs.main_image_size_actual,
  colDefs.number_of_videos_actual,
  colDefs.review_ratings_actual,
  colDefs.review_average_rating_actual,
  colDefs.retail_price_actual,
  colDefs.amazon_offer_has_buybox_actual,
  colDefs.buybox_fufillment_type_actual,
  colDefs.best_seller_rank_actual,
  colDefs.best_seller_rank_category_actual,
  colDefs.amazon_choice_actual
]

export function ProductActuals(props) {  
  const context = useContext(ViewContext);
  const gridRef = useRef();
  const [message, setMessage] = useState('');
  const [customFieldColumns, setCustomFieldColumns] = useState([colDefs.market,
    colDefs.sku,
    colDefs.asin,
    colDefs.status,
    colDefs.paused_until,
    colDefs.last_scan,
    colDefs.priority,]);
  
  const colData = useAPI(`/api/custom-fields/active?category=standard`);
  const data = useAPI('/api/products/actuals');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let tmp = [];
    
    colData?.data?.forEach(cusCol => {
      if (['asin', 'sku', 'market', 'status', 'paused_until', 'last_scan', 'priority'].includes(cusCol.column_name)) {
        let defaults = {};

        if (cusCol.column_name === 'asin') {
          defaults = {
            ...colDefs[cusCol.column_name],
            cellRendererParams : {...colDefs[cusCol.column_name].cellRendererParams, copy }
          }
        }

        tmp.push({
          field: cusCol.column_name,
          headerName: cusCol.column_label,
          sortable: true,
          minWidth:60,
          ...defaults,
          editable: false,
        })
          
      }
    })

    setCustomFieldColumns(tmp)
  }, [colData])

  async function copy(text) {
    try {
      await navigator.clipboard.writeText(text);
      context.notification.show(`copied to clipboard!`, 'success', true);
    } catch (err) {
      context.notification.show('Could not copy text to clipboard:', 'error', true);
    }
  }
  
  const actualKeyNameMapping = useMemo(() => {
    let actualKeyNameMappingTmp = {};

    ACTUAL_COLS.forEach(a => {
      actualKeyNameMappingTmp = { ...actualKeyNameMappingTmp, [a.headerName]: `${a.headerName} actual`}
    })
    
    return actualKeyNameMappingTmp;
  })

  useEffect(() => {
    if (!data.loading && data?.data?.length === 0) {
      setMessage('Please load your products to get started')
    }

    if (data?.data) {
      setProducts(data?.data);
    }
  }, [data?.data]);

  const myHeaderCallback = (params) => {
    var displayName = params.columnApi.getDisplayNameForColumn(params.column);
    return actualKeyNameMapping[displayName] || displayName;
  }
    const downloadCSV = useCallback(() => {
    if (products.length > 0) {
      gridRef.current.api.exportDataAsExcel({
        fileName: 'ProductActuals',
        sheetName: 'ProductActuals',
        processHeaderCallback: myHeaderCallback,
        columnKeys: [...customFieldColumns.map(e => e.field), ...ACTUAL_COLS.map(e => e.field)]
      });
    } else {
      context.notification.show('No products added yet!', 'error', true);
    }

  }, [products]);

  return (
    <Fragment>
      {!props.hideNav && <ProductNav />}
      <Animate>
      {message && <Message
        text={message}
        type='info'
      />}

          <TitleRow title='Live Data On Amazon'>
            <div style={{ display: 'flex' }}>
            <Button small text='Download' action={downloadCSV} />
            </div>
          </TitleRow>

        <Card>
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <GridTable
            gridRef={gridRef}
            width='100%'
            columnDefs={[
              ...customFieldColumns,
              ...ACTUAL_COLS
                           
            ]}
            defaultColDef = {{
              //flex: 1,
              filter: true,
              filterParams: {
                excelMode: 'windows',
            },
              floatingFilter:true,
              
              resizable: true
            }}
            rowData={products}
            loading={data.loading}
          />
          </div>
        </Card>
      </Animate>
    </Fragment>
  );
}
