export const MARKETS = [
  { label: 'United States', value: 'US', amazonUrl: 'https://amazon.com' },
  { label: 'Australia', value: 'AU', amazonUrl: 'https://amazon.com.au' },
  { label: 'Canada', value: 'CA', amazonUrl: 'https://amazon.ca' },
  { label: 'France', value: 'FR', amazonUrl: 'https://amazon.fr' },
  { label: 'Germany', value: 'DE', amazonUrl: 'https://amazon.de' },
  { label: 'Italy', value: 'IT', amazonUrl: 'https://amazon.it' },
  { label: 'Japan', 'value': 'JP', 'amazonUrl': 'https://amazon.co.jp'},
  { label: 'Mexico', value: 'MX', amazonUrl: 'https://amazon.com.mx' },
  { label: 'Saudi Arabia', value: 'SA', amazonUrl: 'https://amazon.sa' },
  { label: 'Spain', value: 'ES', amazonUrl: 'https://amazon.es' },
  { label: 'UAE', value: 'AE', amazonUrl: 'https://amazon.ae' },
  { label: 'United Kingdon', value: 'UK', amazonUrl: 'https://amazon.co.uk' },
];
