import { Card } from 'components/lib';
import { CSSTransition } from 'react-transition-group';
import './modal.scss';

const ModalWithoutContext = ({
  title = '',
  onClose = () => {},
  children = null
}) => {
  return (
    <CSSTransition in appear timeout={0} classNames='modal'>
      <div className='modal' onClick={(e) => e.target === e.currentTarget && onClose()}>
        <div className='modal-content'>
          <Card title={title}>
            {children}
          </Card>
        </div>
      </div>
    </CSSTransition>  
  )
}

export default ModalWithoutContext;
