/***
*
*   USERS
*   Enables an editor to manage the users in their application
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ViewContext, Card, AccountNav, Table, Button, Animate, TitleRow,
  useAPI, usePermissions, Event, AuthContext } from 'components/lib';
import { AccountUserNav } from 'components/nav/sub/account/users';

export function OtherUsers(props){

  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const permissions = usePermissions();
  const data = useAPI('/api/account/other-users');
  const [dashboardId, setDashboardId] = useState('')
  const [users, setUsers] = useState([]);

  function inviteAccountUser(){

    context.modal.show({
      title: 'Add new account',
      form: {
        name: {
          label: 'Name',
          type: 'text',
          required: true,
        },
        email: {
          label: 'Email',
          type: 'text',
          required: true,
        },
        permission: {
          type: 'hidden',
          value: 'owner'
        }
      },
      buttonText: 'Send Invite',
      url: '/api/invite',
      method: 'POST',

      }, (form, res) => {

        // add the invited user to the
        if (res.length){

          const state = [...users];

          res.forEach(invite => {
            if (!state.find(x => x.id === invite.id)){
              state.push({

                id: invite.id,
                name: '',
                email: invite.email,
                date_created: invite.date_sent,
                permission: invite.permission || 'user',
                status: 'Invited',
                actions: {

                  invite: resendInvite,
                  delete: deleteInvite

                },
              });
            }
          });

          // Event('invited_user');
          setUsers(state);

        }
    });
  }

  function inviteChildUser(){

    context.modal.show({
      title: 'Add User',
      form: {
        parent_account: {
          label: 'Parent account',
          type: 'select',
          options: users.filter(a => ['master', 'owner'].includes(a.permission)).map(a => ({ value: a.email, label: a.email })),
          required: true
        },
        email: {
          label: 'Email',
          type: 'text',
          required: true,
        },
        permission: {
          label: 'Permission',
          type: 'select',
          default: 'user',
          options: permissions?.data?.list?.filter(x => x.value !== 'owner') 
        },
      },
      buttonText: 'Send Invite',
      text: 'To invite more than one user, seperate the emails with a comma',
      url: '/api/invite',
      method: 'POST',

      }, (form, res) => {

        // add the invited user to the
        if (res.length){

          const state = [...users];

          res.forEach(invite => {
            if (!state.find(x => x.id === invite.id)){
              state.push({

                id: invite.id,
                name: '',
                email: invite.email,
                date_created: invite.date_sent,
                permission: invite.permission || 'user',
                status: 'Invited',
                actions: {

                  invite: resendInvite,
                  delete: deleteInvite

                },
              });
            }
          });

          // Event('invited_user');
          setUsers(state);

        }
    });
  }

  function editUser(data, callback){

    context.modal.show({
      title: 'Update User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        name: {
          label: 'Name',
          type: 'text',
          required: true,
          value: data.name,
          errorMessage: 'Please enter a name'
        },
        email: {
          label: 'Email',
          type: 'email',
          value: data.email,
          required: true,
        },
        permission: {
          label: 'Permission',
          type: data.permission === 'owner' ? null : 'select',
          options: permissions?.data?.list?.filter(x => x.value !== 'owner') ,
          default: data.permission
        }
      },
      buttonText: 'Save',
      url: '/api/user',
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteUser(data, callback){

    context.modal.show({
      title: 'Delete User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete User',
      text: `Are you sure you want to delete ${data.name}?`,
      url: '/api/user/by-master',
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();

    });
  }

  function deleteInvite(data, callback){
    
    context.modal.show({
      title: 'Delete Invite',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete Invite',
      text: `Are you sure you want to delete the invite for ${data.email}?`,
      url: '/api/invite/by-master',
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(`${data.email}'s invite was deleted`, 'success', true);
      callback();

    });
  }

  async function resendInvite(data){
    try {

      await axios({ 
        
        url: '/api/invite',
        method: 'post',
        data: { email: data.email }
      
      });
      
      context.notification.show(`Invite re-sent to ${data.email}`, 'success', true);

    }
    catch(err){

      context.handleError(err);

    }
  }
  
  useEffect(() => {

    // format the user list
    let list = [];

    if (data?.data?.users?.length){
      list = data.data.users.map(x => {
        return {

          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.date_created,
          permission: x.permission,
          status: 'Registered'

        }
      })
    }

    console.log('Data User: ', list);

    if (data?.data?.invites?.length){
      data.data.invites.forEach(x => {
        list.push({

          id: x.id,
          name: '',
          email: x.email,
          date_created: x.date_sent,
          permission: x.permission || 'user',
          status: 'Invited',

        });
      });
    }

    setUsers(list);

  }, [data]);

  // attach the per row actions for invites
  if (users.length){
    users.forEach(u => {
      if (u.status === 'Invited'){
        u.actions = {

          invite: resendInvite,
          delete: deleteInvite

        }
      }
    })
  }

  function editDashboardId () {
    context.modal.show(
      {
        title: 'Update dashboard id',
        form: {
          aws_quicksight_dashboard_id: {
            label: 'Dashboard Id',
            type: 'text',
            value: dashboardId,
            required: true,
          },
        },
        buttonText: 'Update',
        url: '/api/account/aws-dashboard-id',
        method: 'PUT',
      },
      (form, res) => {
        if (res.status === 200) {
          setDashboardId(form.aws_quicksight_dashboard_id.value)
        }
      }
    );
  }

  function editDashboardIdForSingleUser () {
    context.modal.show(
      {
        title: 'Update dashboard id',
        form: {
          email: {
            label: 'Email',
            type: 'select',
            options: users.filter(a => ['master', 'owner'].includes(a.permission)).map(a => ({ value: a.email, label: a.email })),
            required: true
          },          
          aws_quicksight_dashboard_id: {
            label: 'Dashboard Id',
            type: 'text',
            // value: dashboardId,
            required: true,
          },
        },
        buttonText: 'Update',
        url: '/api/account/single-aws-dashboard-id',
        method: 'PUT',
      }
    );    
  }

  return (
    <Fragment>

      <AccountNav />
      <Animate>

        <TitleRow title='Manage Users'>
        {authContext?.permission?.master &&
            <Button small color='green' text='Edit dashboard id (for all users)' action={editDashboardId} style={{ marginRight: '10px' }} />
        }

        {authContext?.permission?.master &&
            <Button small color='green' text='Edit dashboard id (for single user)' action={editDashboardIdForSingleUser} style={{ marginRight: '10px' }} />
        }

          <Button small text='Add new account' action={ inviteAccountUser } style={{ marginRight: '10px' }} />
          <Button small text='Add User' action={ inviteChildUser }/>
        </TitleRow>
        
        <Card>
          <AccountUserNav />
          <Table
            search
            className='restrict-width'
            data={ users }
            loading={ data.loading }
            show={['email', 'name', 'date_created', 'last_login', 'permission', 'status']}
            badge={{ col: 'status', color: 'blue', condition: [

              { value: 'registered', color: 'green' },
              { value: 'invited', color: 'blue' }

            ]}}
            actions={{ edit: editUser, delete: deleteUser, email: true }}
          />
        </Card>

      </Animate>
    </Fragment>
  );
}
