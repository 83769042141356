import React from 'react';
import Axios from 'axios';

// components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { View } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';

// settings
const StripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const routes = [
  ...require('routes/setup').default,
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
  ...require('routes/products').default,
  ...require('routes/product-issues').default,
  ...require('routes/reports').default
];

export default function App(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  Axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  }
  // console.log({ backend: process.env.REACT_APP_SERVER_URL, stripe: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY });
  // render the routes
  return (
    <Elements stripe={StripePromise}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            {routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    route.permission ? (
                      <PrivateRoute permission={route.permission}>
                        <View display={route.view} layout={route.layout} title={route.title} />
                      </PrivateRoute>
                    ) : (
                      <View display={route.view} layout={route.layout} title={route.title} />
                    )
                  }
                />
              );
            })}

            {/* <Route path='*' element={ <View display={ NotFound } layout='home' title='404 Not Found' /> }/> */}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Elements>
  );
}
