/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment, useContext, useEffect } from 'react';
import { Row, Button, Hero, Features, Testimonial, AuthContext, useNavigate } from 'components/lib';
import ImgDashboard from './images/dashboard.png';
import ImgKyleGawley from './images/kyle-gawley.jpg';

export function Home(props){
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (context.user?.token) {
      navigate('/dashboard')
    }
  }, [])

  return(
    <Fragment>

      <Hero
        title='Keep Your Amazon Pages Healthly & Get More Sales.'
        tagline='ASINRx alerts you in real-time if there are any issues that could impact sales.  Discover and fix issues before they impact sales.   Sleep better.'
        
      />

      <Row title='How ASINRx Works' color='white'>
        <Features />
      </Row>

      <Row color='brand'>
        <Testimonial
          text='ASINRx identifed when one of our top 10 asins became unbuyable.  We were able to fix it without impacting our sales goals.'
          author='Nate Friedman'
          image={ ImgKyleGawley }
        />
      </Row>

      <Row title='Start Monitoring Your Listings and Get Your Health Check Today For FREE' align='center'>
        {/* <Button goto='/signup' text='Sign Up Now' className='inline-block' big/> */}
      </Row>
    </Fragment>
  );
}
