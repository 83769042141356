/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, User } from 'components/lib';
import Style from './app.module.scss';

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);

  return (
    <Fragment>
      <AppNav
        key = {+new Date()}
        items={[
          { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
          { label: 'My Products', icon: 'shopping-bag', link: '/products', hide: !context?.user?.accounts?.has_product_access },
          { label: 'Open Issues', icon: 'loader', link: '/issues', hide: !context?.user?.accounts?.has_product_issue_access },
          { label: 'Reports', icon: 'file-text', link: '/reports', hide: !context?.user?.accounts?.has_reports_access },
          { label: 'Account', icon: 'user', link: '/account' },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout },
        ]}
      />

      <main className={Style.app}>
        <Header title={props.title}>
          <User />
        </Header>

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
