import { useState, useContext } from 'react';
import axios from 'axios';
import { ProductNav, FileInput, Button, ViewContext, Loader, ModalWithoutContext, Card } from 'components/lib';
import { utils, writeFileXLSX, read } from 'xlsx';

export const GeneralUpload = ({
    handleCloseModal = () => {}
}) => {
    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState([]);

    const submit = () => {

        if (file.length === 0) {
            context.notification.show(`Please select a xlsx file to upload.`, 'error', true);
            return;            
        }
        
        setLoading(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonExcelData = new Blob([JSON.stringify(utils.sheet_to_json(worksheet, {defval: '', cellDates: true, cellNF: false, cellText: false, raw:false}))], {type : 'application/json'});

            let formData = new FormData(), headers = {};

            headers['Content-Type'] = 'multipart/form-data';
            headers['Accept'] = 'application/json';
    
            formData.append('dataFile', jsonExcelData);
    
            try {
                let res = await axios({
                    method: 'POST',
                    headers,
                    url: '/api/products/general-excel-import',
                    data: formData
                  });
        
                  setFile([])
    
                  if (res.data.data.invalidProducts.length > 0 || res.data.data.productsWithDuplicateSku.length > 0) {
                    const wb = utils.book_new();
    
                    if( res.data.data.invalidProducts.length > 0 ) {
                        const ws1 = utils.json_to_sheet(res.data.data.invalidProducts);
                        utils.book_append_sheet(wb, ws1, "Invalid");    
                    }
    
                    if( res.data.data.productsWithDuplicateSku.length > 0 ) {
                        const ws2 = utils.json_to_sheet(res.data.data.productsWithDuplicateSku);
                        utils.book_append_sheet(wb, ws2, "Duplicate SKUs");
                    }
    
                    writeFileXLSX(wb, "Products failed to imports.xlsx");
    
                    context.notification.show(`Please see the downloaded \'Products failed to imports.xlsx\' for products not uploaded.`, 'error', true);
                  } else {
                    context.notification.show(`All data successfully updated.`, 'success', true);            
                  }
                              
            } catch (error) {
              context.notification.show(error?.response?.data?.message || error.message, 'error');
            }

            setLoading(false)
            handleCloseModal()
        };

        reader.readAsArrayBuffer(file[0].data);
       }

    return (
        <ModalWithoutContext onClose={handleCloseModal} title='Upload file'>
             <Card style ={{    marginTop: '-40px', paddingBottom: '50px'}}>
            <FileInput value={file} name='general-file' max={1} onChange={(name, fileData, isValid) => {
                if (fileData[0].data) {
                    setFile(fileData)
                } else {
                    setFile([])
                }
                }}
            />
            <Button
                color='green'
                loading={ loading }
                text="Upload"
                action={ submit }
                fullWidth
            />
             </Card>
        </ModalWithoutContext>
    )
}