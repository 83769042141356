import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import moment from 'moment';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui-dist/jquery-ui.min.css';

const DatepickerEditor = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useEffect(() => {
    $(inputRef.current).datepicker({
      dateFormat: 'yy-mm-dd',
      showButtonPanel: true,
      beforeShow: forEverBtn,
      onClose: () => {
        props.stopEditing();
      },
    });

    $(inputRef.current).focus()

    if (props.setInputRef) {
      props.setInputRef(inputRef);
    }
  }, []);

  function forEverBtn(input, inst) {
    $(document).ready(function() {
        $('.ui-datepicker-current').hide();

        var customButton = $('<button class="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all">Forever</button>');
        $(inst.dpDiv).find('.ui-datepicker-buttonpane').append(customButton);

        customButton.on('click', function() {
            $(inputRef.current).datepicker("setDate", new Date('2099-12-31'));
            handleDateSelect()
        });
  })
}
  useEffect(() => {
    $(inputRef.current).datepicker('setDate', moment(props.value).format('YYYY-MM-DD'));
    
    if (props.value) {
      setTimeout(() => $(inputRef.current).css('padding-left', '27px'), 0)          
    } else {
        setTimeout(() => $(inputRef.current).css('padding-left', '13px'), 0)
    }
}, [props.value]);

  const handleDateSelect = (event, ui) => {
    $(inputRef.current).datepicker('hide');
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return $(inputRef.current).datepicker('getDate');
    },
  
    afterGuiAttached: () => {
      $(inputRef.current).datepicker({
        onSelect: handleDateSelect,
        showButtonPanel: true,
        dateFormat: 'yy-mm-dd',
        beforeShow: forEverBtn,
    });
    },
  }));

  return ( <input ref={inputRef} type="text" /> );
});

export { DatepickerEditor };
