import React, { Fragment, useState, useEffect } from 'react';
import { Card, Animate, ProgressBar, Grid, AccountNav, useAPI } from 'components/lib';

const PlanUsage = () => {
  const [plan, setPlan] = useState([]);
  const planConsumption = useAPI('/api/account/plan-consumption');

  useEffect(() => {
    if (planConsumption.data) {
      const data = planConsumption.data;

      setPlan([
        { label: `${data.high.available} out of ${data.high.total} High Priority points are available`, value: `${(((data.high.total - data.high.available) / data.high.total) * 100).toFixed(2)}%` },
        {
          label: `${data.medium.available} out of ${data.medium.total} Medium Priority points are available`,
          value: `${(((data.medium.total - data.medium.available) / data.medium.total) * 100).toFixed(2)}%`,
        },
        { label: `${data.low.available} out of ${data.low.total} Low Priority points are available`, value: `${(((data.low.total - data.low.available) / data.low.total) * 100).toFixed(2)}%` },
        { label: `${data.unscanned.available} out of ${data.unscanned.total} unscanned Priority points are available`, value: `${(((data.unscanned.total - data.unscanned.available) / data.unscanned.total) * 100).toFixed(2)}%` },
      ]);
    }
  }, [planConsumption]);
  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card title='Plan Usage' loading={planConsumption.loading}>
          {plan.map((item) => {
            return <ProgressBar key={item.label} label={item.label} progress={item.value} />;
          })}
        </Card>
      </Animate>
    </Fragment>
  );
};

export { PlanUsage };
