import { Reports } from 'views/reports/reports';

const Routes = [
  {
    path: '/reports',
    view: Reports,
    layout: 'reports',
    permission: 'user',
    title: 'Reports',
  }
];

export default Routes;
