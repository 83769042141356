import moment from 'moment';

export const relativeTime = (timeStamp) => {
  const diff = moment().diff(timeStamp, 'hours');

  if (diff < 2) {
    const minuteCount = moment().diff(timeStamp, 'minutes');
    return minuteCount > 1 ? `${minuteCount} minutes ago` : `${minuteCount} minute ago`;
  } else if (diff < 48) {
    return `${diff} hours ago`;
  } else {
    const dayCount = moment().diff(timeStamp, 'days');
    return dayCount > 1 ? `${dayCount} days ago` : `${dayCount} day ago`;
  }
};
