import React, { Fragment, useEffect, useContext, useState } from 'react';
import { ViewContext, AccountNav, Animate, Card, Form, useAPI,Notification, Table, Button, TitleRow, AuthContext, ModalWithoutContext } from 'components/lib';
import Style from 'components/form/form.tailwind';
// import axios from 'axios';

export function Reports(props) {
  const [refreshToken, setRefreshToken] = useState(null);
  const data = useAPI('/api/account/list', null, refreshToken);
  const [accounts, setAccounts] = useState([]);
  // const [showModal, setShowModal] = useState(false);
  // const [revokeFromEmail, setRevokeFromEmail] = useState(null);

  const authContext = useContext(AuthContext);
  const context = useContext(ViewContext);
  
  useEffect(() => {
    if (data.data) {
      const tmp = data.data.map((u) => ({ 
        id: u.id, 
        label: u.owner_email,
        reports: u.has_reports_access ? 'Yes' : 'No',
        product: u.has_product_access ? 'Yes' : 'No',
        product_issue: u.has_product_issue_access ? 'Yes' : 'No',
      }));
      setAccounts(tmp);
    }
  }, [data]);

  function editUser(data, callback){
    context.modal.show({
      title: 'Update User',
      form: {
        accountId: {
          type: 'hidden',
          value: data.id
        },
        email: {
          label: 'Email',
          type: 'email',
          value: data.label,
          required: true,
          readonly: 'readonly',
        },
        has_reports_access: {
          label: 'Reports',
          type: 'select',
          default: data.reports.toLowerCase(),
          options: [{ value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}]
        },
        has_product_access: {
          label: 'Products',
          type: 'select',
          default: data.product.toLowerCase(),
          options: [{ value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}]
        },
        has_product_issue_access: {
          label: 'Product Issues',
          type: 'select',
          default: data.product_issue.toLowerCase(),
          options: [{ value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}]
        }
      },
      buttonText: 'Update',
      url: '/api/account/update-access',
      method: 'PUT',
    }, (res) => {
      context.notification.show(data.name + ' was updated', 'success', true);
      setRefreshToken(+new Date())
    });
  }
  
  return (
    <Fragment>
      <Notification />
      <AccountNav />
      <Animate>
        <Card className={props.className}>
        <Table
            search
            data={ accounts }
            show={['id', 'label', 'reports', 'product', 'product_issue']}
            loading={ data.loading }
            actions={{ edit: editUser }}

          />          
        </Card>
      </Animate>
    </Fragment>
  );
}
