import { ProductsFindings } from 'views/productIssues/productsFindings';
import { History } from 'views/productIssues/history';
import { CustomFieldSettings } from 'views/productIssues/CustomFieldSettings';

const Routes = [ {
    path: '/issues',
    view: ProductsFindings,
    layout: 'app',
    permission: 'user',
    title: 'Products Findings',
  },
  {
    path: '/issues/paused',
    view: ProductsFindings,
    layout: 'app',
    permission: 'user',
    title: 'Paused Findings',
  },
  {
    path: '/issues/resolved',
    view: ProductsFindings,
    layout: 'app',
    permission: 'user',
    title: 'Manual marked as resolved products',
  },
  {
    path: '/issues/history',
    view: History,
    layout: 'app',
    permission: 'user',
    title: 'Issues History',
  },
  {
    path: '/issues/custom-fields',
    view: CustomFieldSettings,
    layout: 'app',
    permission: 'user',
    title: 'Custom fields settings',
  }
];

export default Routes;