import { Animate, Card, GridTable, Message, ProductNav, TitleRow, useAPI, Button, ViewContext } from "components/lib";
import { Fragment, useEffect, useRef, useState , useCallback, useContext } from "react";
import colDefs from './productColDef'
import axios from 'axios';

const ContentGap = (props) => {
  const [message, setMessage] = useState('');
  const [deltas, setDeltas] = useState([])
  const context = useContext(ViewContext);

  const gridRef = useRef();

  const data = useAPI('/api/products/content-gap')
  
  useEffect(() => {
    if (!data.loading && data?.data?.length === 0) {
      setMessage('Please load your products to get started')
    }

    if (data?.data) {
      setDeltas(data?.data.map((d, index) => ({...d, id: `contentGap-${index + 1}`})));      
    }
  }, [data?.data]);

  const downloadCSV = useCallback(() => {
      gridRef.current.api.exportDataAsExcel({
        fileName: 'gaps',
        sheetName: 'gaps',
        allColumns: true
      });
  }, [data]);


  async function copy(text) {
    try {
      await navigator.clipboard.writeText(text);
      context.notification.show(`copied to clipboard!`, 'success', true);
    } catch (err) {
      console.error('Could not copy text to clipboard:', err);
    }
  }

  const handleChange = async (row) => {
    const data = { id: row.data.productId, [row.data.field_name]: row.newValue }
    const tmpDeltas = [...deltas];
    const index = row.node.rowIndex;

    try {
      const res = await axios({
        url: '/api/products',
        method: 'patch',
        data
      })
  
      const invalidValues = res.data.data.invalidValues;

      const invalidKeys = Object.keys(invalidValues);
  
      if(invalidKeys.length > 0){
        const messageStr = `${invalidValues[invalidKeys[0]]} is not a valid value for ${row.data.field_name}`;
        throw new Error(messageStr)
      } else {
        const messageStr = `${row.data.field_name} updated successfully!`;
        const productWithNewValue = { ...deltas[index], [row.colDef.field]: row.newValue};
        
        tmpDeltas.splice(index, 1, productWithNewValue);
        context.notification.show(messageStr, 'success', true);        
        setDeltas(tmpDeltas)
      }
    } catch (error) {
      const productWithOldValue = { ...deltas[index], [row.colDef.field]: row.oldValue};
      tmpDeltas.splice(index, 1, productWithOldValue);
      context.notification.show(error?.response?.data?.message || error.message, 'error', true);
      setDeltas(tmpDeltas)
    }
  }

  return (
    <Fragment>
      <ProductNav />
      <Animate>
        {message && <Message text={message} type='info' />}
        <TitleRow title='Product Fields With Issues'>
            <div style={{ display: 'flex' }}>
            <Button small text='Download' action={downloadCSV} />
            </div>
          </TitleRow>
        <Card>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <GridTable
            gridRef={gridRef}
            width='100%'
            columnDefs={[
              colDefs.productId,
              {
                ...colDefs.asin,
                cellRendererParams : {...colDefs.asin.cellRendererParams, copy }
              }, 
              colDefs.sku,
              colDefs.market,
              //colDefs.title,
              {field: 'title', editable: true, headerName: 'Title', sortable: true,  minWidth:120, width:300},

              colDefs.field_name,
              colDefs.master_value,
              colDefs.actual_value,
              colDefs.first_scan,
              colDefs.last_scan
            ]}
            defaultColDef = {{
              //flex: 1,
              filter: true,
              filterParams: {
                excelMode: 'windows',
            },
              floatingFilter:true,
              resizable: true,
              onCellValueChanged: handleChange
            }}
            rowData={deltas}
            loading={data.loading}
          />
          </div>
        </Card>
      </Animate>
    </Fragment>
  )
}

export { ContentGap };